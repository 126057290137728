export type Pumpy = {
  version: "0.1.0";
  name: "pumpy";
  constants: [
    {
      name: "AUTHORITY_SEED";
      type: "string";
      value: '"authority"';
    },
    {
      name: "VIRTUAL_SOL";
      type: "u64";
      value: "20 * LAMPORTS_PER_SOL";
    },
    {
      name: "NEW_CHAIN_VOLUME_THRESHOLDS";
      type: {
        array: ["u64", 7];
      };
      value: "[LAMPORTS_PER_SOL / 2 , LAMPORTS_PER_SOL , 2 * LAMPORTS_PER_SOL , 4 * LAMPORTS_PER_SOL , 8 * LAMPORTS_PER_SOL , 16 * LAMPORTS_PER_SOL , 32 * LAMPORTS_PER_SOL ,]";
    }
  ];
  instructions: [
    {
      name: "createAmm";
      accounts: [
        {
          name: "amm";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: false;
          docs: ["The admin of the AMM"];
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
          docs: ["The account paying for all rents"];
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
          docs: ["Solana ecosystem accounts"];
        }
      ];
      args: [
        {
          name: "id";
          type: "publicKey";
        },
        {
          name: "fee";
          type: "u16";
        },
        {
          name: "maxPerWallet";
          type: "u64";
        }
      ];
    },
    {
      name: "createPool";
      accounts: [
        {
          name: "amm";
          isMut: false;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "poolAuthority";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "poolAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
          docs: ["The account paying for all rents"];
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
          docs: ["Solana ecosystem accounts"];
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "createTokenMint";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "metadataAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintAccount";
          isMut: true;
          isSigner: true;
        },
        {
          name: "associatedTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenMetadataProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "tokenDecimals";
          type: "u8";
        },
        {
          name: "tokenName";
          type: "string";
        },
        {
          name: "tokenSymbol";
          type: "string";
        },
        {
          name: "tokenUri";
          type: "string";
        },
        {
          name: "totalSupply";
          type: "u64";
        }
      ];
    },
    {
      name: "depositLiquidity";
      accounts: [
        {
          name: "pool";
          isMut: false;
          isSigner: false;
        },
        {
          name: "poolAuthority";
          isMut: true;
          isSigner: false;
        },
        {
          name: "depositor";
          isMut: false;
          isSigner: true;
          docs: ["The account paying for all rents"];
        },
        {
          name: "mintA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "poolAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "depositorAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
          docs: ["The account paying for all rents"];
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
          docs: ["Solana ecosystem accounts"];
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amountA";
          type: "u64";
        },
        {
          name: "docId";
          type: "string";
        }
      ];
    },
    {
      name: "buy";
      accounts: [
        {
          name: "amm";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: false;
          isSigner: false;
        },
        {
          name: "poolAuthority";
          isMut: true;
          isSigner: false;
        },
        {
          name: "trader";
          isMut: false;
          isSigner: true;
          docs: ["The account doing the swap"];
        },
        {
          name: "mintA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "poolAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "traderAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
          docs: ["The account paying for all rents"];
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
          docs: ["Solana ecosystem accounts"];
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "solInput";
          type: "u64";
        },
        {
          name: "minTokenOutput";
          type: "u64";
        }
      ];
    },
    {
      name: "sell";
      accounts: [
        {
          name: "amm";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: false;
          isSigner: false;
        },
        {
          name: "poolAuthority";
          isMut: true;
          isSigner: false;
        },
        {
          name: "trader";
          isMut: false;
          isSigner: true;
          docs: ["The account doing the swap"];
        },
        {
          name: "mintA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "poolAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "traderAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
          docs: ["The account paying for all rents"];
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
          docs: ["Solana ecosystem accounts"];
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "tokenInput";
          type: "u64";
        },
        {
          name: "minSolOutput";
          type: "u64";
        }
      ];
    },
    {
      name: "createChain";
      accounts: [
        {
          name: "amm";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: false;
          isSigner: false;
        },
        {
          name: "poolAuthority";
          isMut: true;
          isSigner: false;
        },
        {
          name: "creator";
          isMut: false;
          isSigner: true;
          docs: ["The account doing the swap"];
        },
        {
          name: "mintA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "poolAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "creatorAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasuryAccountA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
          docs: ["Solana ecosystem accounts"];
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "docId";
          type: "string";
        }
      ];
    },
    {
      name: "decrementChain";
      accounts: [
        {
          name: "amm";
          isMut: true;
          isSigner: false;
        },
        {
          name: "admin";
          isMut: false;
          isSigner: true;
          docs: ["The admin of the AMM"];
        }
      ];
      args: [
        {
          name: "decrementAmount";
          type: "u8";
        }
      ];
    },
    {
      name: "proxyInitialize";
      docs: ["initilaize market in raydium"];
      accounts: [
        {
          name: "ammProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "amm";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammOpenOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammLpMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammCoinMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammPcMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammCoinVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammPcVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammTargetOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammConfig";
          isMut: false;
          isSigner: false;
        },
        {
          name: "createFeeDestination";
          isMut: true;
          isSigner: false;
        },
        {
          name: "marketProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "market";
          isMut: false;
          isSigner: false;
        },
        {
          name: "userWallet";
          isMut: true;
          isSigner: true;
        },
        {
          name: "userTokenCoin";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userTokenPc";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userTokenLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "sysvarRent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "nonce";
          type: "u8";
        },
        {
          name: "openTime";
          type: "u64";
        },
        {
          name: "initPcAmount";
          type: "u64";
        },
        {
          name: "initCoinAmount";
          type: "u64";
        }
      ];
    }
  ];
  accounts: [
    {
      name: "amm";
      type: {
        kind: "struct";
        fields: [
          {
            name: "id";
            docs: ["The primary key of the AMM"];
            type: "publicKey";
          },
          {
            name: "admin";
            docs: ["Account that has admin authority over the AMM"];
            type: "publicKey";
          },
          {
            name: "fee";
            docs: ["The LP fee taken on each trade, in basis points"];
            type: "u16";
          },
          {
            name: "maxPerWallet";
            docs: ["Max tokens per wallet"];
            type: "u64";
          },
          {
            name: "availableChains";
            docs: [
              "Count of available chains",
              "only go up when buy",
              "only go down when create chain"
            ];
            type: "u8";
          },
          {
            name: "chainCount";
            docs: ["count of chained memes"];
            type: "u8";
          },
          {
            name: "lock";
            docs: ["Bool value for lock of bonding curves"];
            type: "bool";
          },
          {
            name: "cumulativeVolume";
            docs: ["Cumulative buy volume"];
            type: "u64";
          }
        ];
      };
    },
    {
      name: "pool";
      type: {
        kind: "struct";
        fields: [
          {
            name: "amm";
            docs: ["Primary key of the AMM"];
            type: "publicKey";
          },
          {
            name: "mintA";
            docs: ["Mint of token A"];
            type: "publicKey";
          }
        ];
      };
    }
  ];
  events: [
    {
      name: "BuyEvent";
      fields: [
        {
          name: "trader";
          type: "publicKey";
          index: false;
        },
        {
          name: "solInput";
          type: "u64";
          index: false;
        },
        {
          name: "minTokenOutput";
          type: "u64";
          index: false;
        },
        {
          name: "tokenOutput";
          type: "u64";
          index: false;
        },
        {
          name: "feeAmount";
          type: "u64";
          index: false;
        },
        {
          name: "tokenInPool";
          type: "u64";
          index: false;
        },
        {
          name: "solInPool";
          type: "u64";
          index: false;
        },
        {
          name: "poolId";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenMintAddr";
          type: "publicKey";
          index: false;
        },
        {
          name: "chainCount";
          type: "u8";
          index: false;
        },
        {
          name: "availableChains";
          type: "u8";
          index: false;
        },
        {
          name: "cumulativeVolume";
          type: "u64";
          index: false;
        },
        {
          name: "cumulativeVolumeThreshold";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "BurnEvent";
      fields: [
        {
          name: "poolId";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenMintAddr";
          type: "publicKey";
          index: false;
        },
        {
          name: "lastCumulativeVolume";
          type: "u64";
          index: false;
        },
        {
          name: "chainCount";
          type: "u8";
          index: false;
        },
        {
          name: "availableChains";
          type: "u8";
          index: false;
        },
        {
          name: "cumulativeVolume";
          type: "u64";
          index: false;
        },
        {
          name: "cumulativeVolumeThreshold";
          type: "u64";
          index: false;
        },
        {
          name: "nextCumulativeVolumeThreshold";
          type: "u64";
          index: false;
        },
        {
          name: "solInPool";
          type: "u64";
          index: false;
        },
        {
          name: "tokenInPool";
          type: "u64";
          index: false;
        },
        {
          name: "burnedAmount";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "AMMCreatedEvent";
      fields: [
        {
          name: "amm";
          type: "publicKey";
          index: false;
        },
        {
          name: "admin";
          type: "publicKey";
          index: false;
        },
        {
          name: "fee";
          type: "u16";
          index: false;
        },
        {
          name: "maxPerWallet";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "ChainCreatedEvent";
      fields: [
        {
          name: "poolId";
          type: "publicKey";
          index: false;
        },
        {
          name: "docId";
          type: "string";
          index: false;
        },
        {
          name: "tokenMintAddr";
          type: "publicKey";
          index: false;
        },
        {
          name: "creator";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenInPool";
          type: "u64";
          index: false;
        },
        {
          name: "solInPool";
          type: "u64";
          index: false;
        },
        {
          name: "ammLocked";
          type: "bool";
          index: false;
        },
        {
          name: "chainCount";
          type: "u8";
          index: false;
        },
        {
          name: "availableChains";
          type: "u8";
          index: false;
        },
        {
          name: "cumulativeVolume";
          type: "u64";
          index: false;
        },
        {
          name: "cumulativeVolumeThreshold";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "PoolCreatedEvent";
      fields: [
        {
          name: "amm";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenPool";
          type: "publicKey";
          index: false;
        },
        {
          name: "solPool";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenMintA";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "TokenMintCreated";
      fields: [
        {
          name: "name";
          type: "string";
          index: false;
        },
        {
          name: "symbol";
          type: "string";
          index: false;
        },
        {
          name: "uri";
          type: "string";
          index: false;
        },
        {
          name: "totalSupply";
          type: "u64";
          index: false;
        },
        {
          name: "metadataAccount";
          type: "publicKey";
          index: false;
        },
        {
          name: "mintAccount";
          type: "publicKey";
          index: false;
        },
        {
          name: "mintedTo";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "DecrementChainEvent";
      fields: [
        {
          name: "admin";
          type: "publicKey";
          index: false;
        },
        {
          name: "previousChainCount";
          type: "u8";
          index: false;
        },
        {
          name: "decrementAmount";
          type: "u8";
          index: false;
        },
        {
          name: "newChainCount";
          type: "u8";
          index: false;
        }
      ];
    },
    {
      name: "PoolDepositEvent";
      fields: [
        {
          name: "amm";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenPool";
          type: "publicKey";
          index: false;
        },
        {
          name: "depositor";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenAddr";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenAmount";
          type: "u64";
          index: false;
        },
        {
          name: "solInPool";
          type: "u64";
          index: false;
        },
        {
          name: "docId";
          type: "string";
          index: false;
        },
        {
          name: "chainCount";
          type: "u8";
          index: false;
        },
        {
          name: "availableChains";
          type: "u8";
          index: false;
        },
        {
          name: "cumulativeVolume";
          type: "u64";
          index: false;
        },
        {
          name: "cumulativeVolumeThreshold";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "SellEvent";
      fields: [
        {
          name: "trader";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenInput";
          type: "u64";
          index: false;
        },
        {
          name: "minSolOutput";
          type: "u64";
          index: false;
        },
        {
          name: "solOutput";
          type: "u64";
          index: false;
        },
        {
          name: "feeAmount";
          type: "u64";
          index: false;
        },
        {
          name: "tokenInPool";
          type: "u64";
          index: false;
        },
        {
          name: "solInPool";
          type: "u64";
          index: false;
        },
        {
          name: "poolId";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenMintAddr";
          type: "publicKey";
          index: false;
        },
        {
          name: "chainCount";
          type: "u8";
          index: false;
        },
        {
          name: "availableChains";
          type: "u8";
          index: false;
        },
        {
          name: "cumulativeVolume";
          type: "u64";
          index: false;
        },
        {
          name: "cumulativeVolumeThreshold";
          type: "u64";
          index: false;
        }
      ];
    }
  ];
  errors: [
    {
      code: 6000;
      name: "InvalidFee";
      msg: "Invalid fee value";
    },
    {
      code: 6001;
      name: "InvalidTooMany";
      msg: "Invalid buy too many tokens";
    },
    {
      code: 6002;
      name: "OutputTooSmall";
      msg: "Output is below the minimum expected";
    },
    {
      code: 6003;
      name: "Unauthorized";
      msg: "Signer account is not the amm admin";
    },
    {
      code: 6004;
      name: "InvalidChainCount";
      msg: "Invalid chain count, must fit within u8";
    },
    {
      code: 6005;
      name: "InvalidChainSOLAmount";
      msg: "Invalid SOL amount for chain";
    },
    {
      code: 6006;
      name: "DocIDRequired";
      msg: "DocID required";
    },
    {
      code: 6007;
      name: "NoAvailableChains";
      msg: "No available chains";
    },
    {
      code: 6008;
      name: "Overflow";
      msg: "Value overflow";
    }
  ];
};

export const IDL: Pumpy = {
  version: "0.1.0",
  name: "pumpy",
  constants: [
    {
      name: "AUTHORITY_SEED",
      type: "string",
      value: '"authority"',
    },
    {
      name: "VIRTUAL_SOL",
      type: "u64",
      value: "20 * LAMPORTS_PER_SOL",
    },
    {
      name: "NEW_CHAIN_VOLUME_THRESHOLDS",
      type: {
        array: ["u64", 7],
      },
      value:
        "[LAMPORTS_PER_SOL / 2 , LAMPORTS_PER_SOL , 2 * LAMPORTS_PER_SOL , 4 * LAMPORTS_PER_SOL , 8 * LAMPORTS_PER_SOL , 16 * LAMPORTS_PER_SOL , 32 * LAMPORTS_PER_SOL ,]",
    },
  ],
  instructions: [
    {
      name: "createAmm",
      accounts: [
        {
          name: "amm",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: false,
          docs: ["The admin of the AMM"],
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
          docs: ["The account paying for all rents"],
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
          docs: ["Solana ecosystem accounts"],
        },
      ],
      args: [
        {
          name: "id",
          type: "publicKey",
        },
        {
          name: "fee",
          type: "u16",
        },
        {
          name: "maxPerWallet",
          type: "u64",
        },
      ],
    },
    {
      name: "createPool",
      accounts: [
        {
          name: "amm",
          isMut: false,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "poolAuthority",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "poolAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
          docs: ["The account paying for all rents"],
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
          docs: ["Solana ecosystem accounts"],
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "createTokenMint",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "metadataAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintAccount",
          isMut: true,
          isSigner: true,
        },
        {
          name: "associatedTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenMetadataProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "tokenDecimals",
          type: "u8",
        },
        {
          name: "tokenName",
          type: "string",
        },
        {
          name: "tokenSymbol",
          type: "string",
        },
        {
          name: "tokenUri",
          type: "string",
        },
        {
          name: "totalSupply",
          type: "u64",
        },
      ],
    },
    {
      name: "depositLiquidity",
      accounts: [
        {
          name: "pool",
          isMut: false,
          isSigner: false,
        },
        {
          name: "poolAuthority",
          isMut: true,
          isSigner: false,
        },
        {
          name: "depositor",
          isMut: false,
          isSigner: true,
          docs: ["The account paying for all rents"],
        },
        {
          name: "mintA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "poolAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "depositorAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
          docs: ["The account paying for all rents"],
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
          docs: ["Solana ecosystem accounts"],
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amountA",
          type: "u64",
        },
        {
          name: "docId",
          type: "string",
        },
      ],
    },
    {
      name: "buy",
      accounts: [
        {
          name: "amm",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: false,
          isSigner: false,
        },
        {
          name: "poolAuthority",
          isMut: true,
          isSigner: false,
        },
        {
          name: "trader",
          isMut: false,
          isSigner: true,
          docs: ["The account doing the swap"],
        },
        {
          name: "mintA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "poolAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "traderAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
          docs: ["The account paying for all rents"],
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
          docs: ["Solana ecosystem accounts"],
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "solInput",
          type: "u64",
        },
        {
          name: "minTokenOutput",
          type: "u64",
        },
      ],
    },
    {
      name: "sell",
      accounts: [
        {
          name: "amm",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: false,
          isSigner: false,
        },
        {
          name: "poolAuthority",
          isMut: true,
          isSigner: false,
        },
        {
          name: "trader",
          isMut: false,
          isSigner: true,
          docs: ["The account doing the swap"],
        },
        {
          name: "mintA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "poolAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "traderAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
          docs: ["The account paying for all rents"],
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
          docs: ["Solana ecosystem accounts"],
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "tokenInput",
          type: "u64",
        },
        {
          name: "minSolOutput",
          type: "u64",
        },
      ],
    },
    {
      name: "createChain",
      accounts: [
        {
          name: "amm",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: false,
          isSigner: false,
        },
        {
          name: "poolAuthority",
          isMut: true,
          isSigner: false,
        },
        {
          name: "creator",
          isMut: false,
          isSigner: true,
          docs: ["The account doing the swap"],
        },
        {
          name: "mintA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "poolAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "creatorAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasuryAccountA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
          docs: ["Solana ecosystem accounts"],
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "docId",
          type: "string",
        },
      ],
    },
    {
      name: "decrementChain",
      accounts: [
        {
          name: "amm",
          isMut: true,
          isSigner: false,
        },
        {
          name: "admin",
          isMut: false,
          isSigner: true,
          docs: ["The admin of the AMM"],
        },
      ],
      args: [
        {
          name: "decrementAmount",
          type: "u8",
        },
      ],
    },
    {
      name: "proxyInitialize",
      docs: ["initilaize market in raydium"],
      accounts: [
        {
          name: "ammProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "amm",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammOpenOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammLpMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammCoinMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammPcMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammCoinVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammPcVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammTargetOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammConfig",
          isMut: false,
          isSigner: false,
        },
        {
          name: "createFeeDestination",
          isMut: true,
          isSigner: false,
        },
        {
          name: "marketProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "market",
          isMut: false,
          isSigner: false,
        },
        {
          name: "userWallet",
          isMut: true,
          isSigner: true,
        },
        {
          name: "userTokenCoin",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userTokenPc",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userTokenLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "sysvarRent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "nonce",
          type: "u8",
        },
        {
          name: "openTime",
          type: "u64",
        },
        {
          name: "initPcAmount",
          type: "u64",
        },
        {
          name: "initCoinAmount",
          type: "u64",
        },
      ],
    },
  ],
  accounts: [
    {
      name: "amm",
      type: {
        kind: "struct",
        fields: [
          {
            name: "id",
            docs: ["The primary key of the AMM"],
            type: "publicKey",
          },
          {
            name: "admin",
            docs: ["Account that has admin authority over the AMM"],
            type: "publicKey",
          },
          {
            name: "fee",
            docs: ["The LP fee taken on each trade, in basis points"],
            type: "u16",
          },
          {
            name: "maxPerWallet",
            docs: ["Max tokens per wallet"],
            type: "u64",
          },
          {
            name: "availableChains",
            docs: [
              "Count of available chains",
              "only go up when buy",
              "only go down when create chain",
            ],
            type: "u8",
          },
          {
            name: "chainCount",
            docs: ["count of chained memes"],
            type: "u8",
          },
          {
            name: "lock",
            docs: ["Bool value for lock of bonding curves"],
            type: "bool",
          },
          {
            name: "cumulativeVolume",
            docs: ["Cumulative buy volume"],
            type: "u64",
          },
        ],
      },
    },
    {
      name: "pool",
      type: {
        kind: "struct",
        fields: [
          {
            name: "amm",
            docs: ["Primary key of the AMM"],
            type: "publicKey",
          },
          {
            name: "mintA",
            docs: ["Mint of token A"],
            type: "publicKey",
          },
        ],
      },
    },
  ],
  events: [
    {
      name: "BuyEvent",
      fields: [
        {
          name: "trader",
          type: "publicKey",
          index: false,
        },
        {
          name: "solInput",
          type: "u64",
          index: false,
        },
        {
          name: "minTokenOutput",
          type: "u64",
          index: false,
        },
        {
          name: "tokenOutput",
          type: "u64",
          index: false,
        },
        {
          name: "feeAmount",
          type: "u64",
          index: false,
        },
        {
          name: "tokenInPool",
          type: "u64",
          index: false,
        },
        {
          name: "solInPool",
          type: "u64",
          index: false,
        },
        {
          name: "poolId",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenMintAddr",
          type: "publicKey",
          index: false,
        },
        {
          name: "chainCount",
          type: "u8",
          index: false,
        },
        {
          name: "availableChains",
          type: "u8",
          index: false,
        },
        {
          name: "cumulativeVolume",
          type: "u64",
          index: false,
        },
        {
          name: "cumulativeVolumeThreshold",
          type: "u64",
          index: false,
        },
      ],
    },
    {
      name: "BurnEvent",
      fields: [
        {
          name: "poolId",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenMintAddr",
          type: "publicKey",
          index: false,
        },
        {
          name: "lastCumulativeVolume",
          type: "u64",
          index: false,
        },
        {
          name: "chainCount",
          type: "u8",
          index: false,
        },
        {
          name: "availableChains",
          type: "u8",
          index: false,
        },
        {
          name: "cumulativeVolume",
          type: "u64",
          index: false,
        },
        {
          name: "cumulativeVolumeThreshold",
          type: "u64",
          index: false,
        },
        {
          name: "nextCumulativeVolumeThreshold",
          type: "u64",
          index: false,
        },
        {
          name: "solInPool",
          type: "u64",
          index: false,
        },
        {
          name: "tokenInPool",
          type: "u64",
          index: false,
        },
        {
          name: "burnedAmount",
          type: "u64",
          index: false,
        },
      ],
    },
    {
      name: "AMMCreatedEvent",
      fields: [
        {
          name: "amm",
          type: "publicKey",
          index: false,
        },
        {
          name: "admin",
          type: "publicKey",
          index: false,
        },
        {
          name: "fee",
          type: "u16",
          index: false,
        },
        {
          name: "maxPerWallet",
          type: "u64",
          index: false,
        },
      ],
    },
    {
      name: "ChainCreatedEvent",
      fields: [
        {
          name: "poolId",
          type: "publicKey",
          index: false,
        },
        {
          name: "docId",
          type: "string",
          index: false,
        },
        {
          name: "tokenMintAddr",
          type: "publicKey",
          index: false,
        },
        {
          name: "creator",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenInPool",
          type: "u64",
          index: false,
        },
        {
          name: "solInPool",
          type: "u64",
          index: false,
        },
        {
          name: "ammLocked",
          type: "bool",
          index: false,
        },
        {
          name: "chainCount",
          type: "u8",
          index: false,
        },
        {
          name: "availableChains",
          type: "u8",
          index: false,
        },
        {
          name: "cumulativeVolume",
          type: "u64",
          index: false,
        },
        {
          name: "cumulativeVolumeThreshold",
          type: "u64",
          index: false,
        },
      ],
    },
    {
      name: "PoolCreatedEvent",
      fields: [
        {
          name: "amm",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenPool",
          type: "publicKey",
          index: false,
        },
        {
          name: "solPool",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenMintA",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "TokenMintCreated",
      fields: [
        {
          name: "name",
          type: "string",
          index: false,
        },
        {
          name: "symbol",
          type: "string",
          index: false,
        },
        {
          name: "uri",
          type: "string",
          index: false,
        },
        {
          name: "totalSupply",
          type: "u64",
          index: false,
        },
        {
          name: "metadataAccount",
          type: "publicKey",
          index: false,
        },
        {
          name: "mintAccount",
          type: "publicKey",
          index: false,
        },
        {
          name: "mintedTo",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "DecrementChainEvent",
      fields: [
        {
          name: "admin",
          type: "publicKey",
          index: false,
        },
        {
          name: "previousChainCount",
          type: "u8",
          index: false,
        },
        {
          name: "decrementAmount",
          type: "u8",
          index: false,
        },
        {
          name: "newChainCount",
          type: "u8",
          index: false,
        },
      ],
    },
    {
      name: "PoolDepositEvent",
      fields: [
        {
          name: "amm",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenPool",
          type: "publicKey",
          index: false,
        },
        {
          name: "depositor",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenAddr",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenAmount",
          type: "u64",
          index: false,
        },
        {
          name: "solInPool",
          type: "u64",
          index: false,
        },
        {
          name: "docId",
          type: "string",
          index: false,
        },
        {
          name: "chainCount",
          type: "u8",
          index: false,
        },
        {
          name: "availableChains",
          type: "u8",
          index: false,
        },
        {
          name: "cumulativeVolume",
          type: "u64",
          index: false,
        },
        {
          name: "cumulativeVolumeThreshold",
          type: "u64",
          index: false,
        },
      ],
    },
    {
      name: "SellEvent",
      fields: [
        {
          name: "trader",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenInput",
          type: "u64",
          index: false,
        },
        {
          name: "minSolOutput",
          type: "u64",
          index: false,
        },
        {
          name: "solOutput",
          type: "u64",
          index: false,
        },
        {
          name: "feeAmount",
          type: "u64",
          index: false,
        },
        {
          name: "tokenInPool",
          type: "u64",
          index: false,
        },
        {
          name: "solInPool",
          type: "u64",
          index: false,
        },
        {
          name: "poolId",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenMintAddr",
          type: "publicKey",
          index: false,
        },
        {
          name: "chainCount",
          type: "u8",
          index: false,
        },
        {
          name: "availableChains",
          type: "u8",
          index: false,
        },
        {
          name: "cumulativeVolume",
          type: "u64",
          index: false,
        },
        {
          name: "cumulativeVolumeThreshold",
          type: "u64",
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: "InvalidFee",
      msg: "Invalid fee value",
    },
    {
      code: 6001,
      name: "InvalidTooMany",
      msg: "Invalid buy too many tokens",
    },
    {
      code: 6002,
      name: "OutputTooSmall",
      msg: "Output is below the minimum expected",
    },
    {
      code: 6003,
      name: "Unauthorized",
      msg: "Signer account is not the amm admin",
    },
    {
      code: 6004,
      name: "InvalidChainCount",
      msg: "Invalid chain count, must fit within u8",
    },
    {
      code: 6005,
      name: "InvalidChainSOLAmount",
      msg: "Invalid SOL amount for chain",
    },
    {
      code: 6006,
      name: "DocIDRequired",
      msg: "DocID required",
    },
    {
      code: 6007,
      name: "NoAvailableChains",
      msg: "No available chains",
    },
    {
      code: 6008,
      name: "Overflow",
      msg: "Value overflow",
    },
  ],
};
