import React from "react";
import TikTokThumbnailCarousel from "./TikTokThumbnailCarousel";
import "./ChainGradient.css";

const Meme = ({ meme, isMobile, interval }) => {
  return (
    <div
      className={`md:mt-4 flex flex-col items-center relative cursor-pointer transition duration-300 ease-in-out rounded-lg ${
        !isMobile
          ? "hover:bg-gray-700 transform hover:-translate-y-1 hover:scale-105"
          : ""
      }`}
      style={isMobile ? { backgroundColor: "#1f1e24" } : {}}>
      <TikTokThumbnailCarousel
        meme={meme}
        isMobile={isMobile}
        interval={interval}
      />
      <p className='text-sm text-yellow-300'>
        {`Clips Chained: ${meme?.chainedMemeLength}`}
      </p>
    </div>
  );
};

export default Meme;
