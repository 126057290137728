import React, { useEffect, useState } from "react";
import {
  transformFirstUpperCase,
  truncateAddress,
  timeAgo,
  formatNumber,
} from "../util/util";
import { eventType } from "../util/util";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import "./ChainGradient.css";

const TransactionsComponent = ({
  profile,
  transactions,
  oldTransactions,
  isMobile,
  isActive,
}) => {
  const [formattedTransactions, setFormattedTransactions] =
    useState(transactions);
  useEffect(() => {
    const updatedTransactions = [];
    for (let i = 0; i < transactions.length; i++) {
      let tx = transactions[i]; // tx set to updatable due to buy and chain check
      if (
        updatedTransactions.length > 0 &&
        tx.hash === transactions[i - 1].hash &&
        tx.type !== eventType.BUY &&
        transactions[i - 1].type !== eventType.BUY
      ) {
        let chainTx;
        // accounts for the edge case where we receive Chain first and Buy later
        if (tx.type === eventType.CHAIN) {
          chainTx = tx;
          tx = transactions[i - 1];
        } else {
          chainTx = transactions[i - 1];
        }
        // must be a burn and chain event, don't push but replace
        updatedTransactions[updatedTransactions.length - 1] = {
          account: chainTx.trader,
          type: eventType.CHAIN_AND_BURN,
          solAmount: 0,
          tickerAmount: -(
            parseInt(tx.burnedAmount, 16) / LAMPORTS_PER_SOL
          ).toFixed(0),
          timestamp: tx.timestamp,
          transaction: tx.hash,
        };
      } else {
        updatedTransactions.push({
          account: tx.trader,
          type: tx.type,
          solAmount:
            tx.type === eventType.CHAIN || tx.type === eventType.BURN
              ? 0
              : tx.type === eventType.MINT
              ? (parseInt(tx.solInPool, 16) / LAMPORTS_PER_SOL).toFixed(2)
              : tx.type === eventType.BUY
              ? (parseInt(tx.inputAmount, 16) / LAMPORTS_PER_SOL).toFixed(2)
              : (parseInt(tx.outputAmount, 16) / LAMPORTS_PER_SOL).toFixed(2),
          tickerAmount:
            tx.type === eventType.CHAIN
              ? 0
              : tx.type === eventType.BURN
              ? -(parseInt(tx.burnedAmount, 16) / LAMPORTS_PER_SOL).toFixed(0)
              : tx.type === eventType.MINT
              ? (parseInt(tx.tokenInPool, 16) / LAMPORTS_PER_SOL).toFixed(0)
              : tx.type === eventType.BUY
              ? Math.floor(parseInt(tx.outputAmount, 16) / LAMPORTS_PER_SOL)
              : Math.floor(parseInt(tx.inputAmount, 16) / LAMPORTS_PER_SOL),
          timestamp: tx.timestamp,
          transaction: tx.hash,
        });
      }
    }
    setFormattedTransactions(updatedTransactions);
  }, [profile.handle, transactions]);

  useEffect(() => {
    const newTxs = transactions.filter(
      (tx) => !oldTransactions.some((oldTx) => oldTx.hash === tx.hash)
    );
    newTxs.forEach((tx, index) => {
      const newTxElement = document.getElementById(`tx-${index}`);
      if (newTxElement) {
        newTxElement.classList.add("animate-vibrate");
        setTimeout(() => {
          newTxElement.classList.remove("animate-vibrate");
        }, 1000);
      }
    });
  }, [transactions, oldTransactions]);

  const getTypeColor = (type) => {
    if (type === eventType.MINT) {
      // return "text-chain-gradient";
      return "text-blue-200";
    } else if (type === eventType.BUY) {
      return "text-yellow-400";
    } else if (type === eventType.SELL) {
      return "text-purple-400";
    } else if (type === eventType.CHAIN) {
      // return "text-shine-gradient";
      return "text-chain-gradient";
    } else if (type === eventType.BURN) {
      return "text-red-600";
    } else if (type === eventType.CHAIN_AND_BURN) {
      return "text-burn-gradient";
    }
  };

  if (!isActive) {
    return null;
  }

  return isMobile ? (
    <div className='shadow-md rounded-lg overflow-hidden text-center'>
      <div className='text-white p-4' style={{ backgroundColor: "#1f1e24" }}>
        <div className='grid grid-cols-5 text-xs md:text-sm'>
          <div>Addr</div>
          <div>Type</div>
          <div>SOL</div>
          <div>{profile.ticker}</div>
          <div>Txn</div>
        </div>
      </div>
      <div>
        {formattedTransactions.length > 0 &&
          formattedTransactions.map((tx, index) => (
            <div
              id={`tx-${index}`}
              key={index}
              className={`grid grid-cols-5 text-xs md:text-sm p-4 text-gray-400 ${
                index % 2 === 0 ? "bg-[#2c2a33]" : "bg-[#151319]"
              }`}>
              <div>
                {tx.type !== eventType.BURN
                  ? truncateAddress(tx.account, 3)
                  : tx.account}
              </div>
              <div className={getTypeColor(tx.type)}>
                {transformFirstUpperCase(tx.type)}{" "}
                {timeAgo(tx.timestamp, isMobile)}
              </div>
              <div>{tx.solAmount}</div>
              <div>{formatNumber(tx.tickerAmount)}</div>
              <a
                href={`https://solscan.io/tx/${tx.transaction}`}
                target='_blank'
                rel='noopener noreferrer'
                className='truncate underline'>
                {truncateAddress(tx.transaction, 3)}
              </a>
            </div>
          ))}
      </div>
    </div>
  ) : (
    <div className='shadow-md rounded-lg overflow-hidden text-center'>
      <div className='text-white p-4' style={{ backgroundColor: "#1f1e24" }}>
        <div className='grid grid-cols-6 text-xs md:text-sm'>
          <div>Address</div>
          <div>Type</div>
          <div>Amount SOL</div>
          <div>Amount {profile.ticker}</div>
          <div>Timestamp</div>
          <div>Transaction</div>
        </div>
      </div>
      <div>
        {formattedTransactions.length > 0 &&
          formattedTransactions.map((tx, index) => (
            <div
              id={`tx-${index}`}
              key={index}
              className={`grid grid-cols-6 text-xs md:text-sm p-4 text-gray-400 ${
                index % 2 === 0 ? "bg-[#2c2a33]" : "bg-[#151319]"
              }`}>
              <div>
                {tx.type !== eventType.BURN
                  ? truncateAddress(tx.account, 4)
                  : tx.account}
              </div>
              <div className={getTypeColor(tx.type)}>
                {transformFirstUpperCase(tx.type)}
              </div>
              <div>{tx.solAmount}</div>
              <div>{tx.tickerAmount}</div>
              <div>{timeAgo(tx.timestamp)}</div>
              <a
                href={`https://solscan.io/tx/${tx.transaction}`}
                target='_blank'
                rel='noopener noreferrer'
                className='truncate underline'>
                {truncateAddress(tx.transaction, 4)}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TransactionsComponent;
