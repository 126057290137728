import React from "react";
import { useNavigate } from "react-router-dom";
import Tab from "./Tab";
import { useWallet } from "@solana/wallet-adapter-react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Tabs = ({
  tabs,
  activeTab,
  setActiveTab,
  onClickCallBack,
  showBackButton,
}) => {
  const { connected } = useWallet();
  const navigate = useNavigate();

  const onClickTab = (key) => {
    setActiveTab(key);
    window.scrollTo(0, 0);
    onClickCallBack(key);
  };

  const stickyTabsStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "#1a191f",
    zIndex: 100,
    display: "flex",
    alignItems: "center",
    // padding: "0.5rem 0",
  };

  const tabsContainerStyle = {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const arrowStyle = {
    position: "absolute",
    cursor: "pointer",
    paddingLeft: "1rem",
  };

  return (
    <div style={stickyTabsStyle}>
      {showBackButton && (
        <div
          style={arrowStyle}
          onClick={() =>
            window.history.length > 1 ? navigate(-1) : navigate("/home")
          }>
          <ArrowBackIosIcon />
        </div>
      )}
      <div style={tabsContainerStyle}>
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            label={tab.label}
            isActive={tab.key === activeTab}
            onClick={() => onClickTab(tab.key)}
          />
        ))}
      </div>
      {/* <div style={{ width: "1rem" }} />{" "} */}
      {/* Empty div for spacing on the right */}
    </div>
  );
};

export default Tabs;
