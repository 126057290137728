import {
  program,
  getAmmKey,
  getPoolKey,
  getPoolAuthority,
} from "./util/anchor.ts";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { TOTAL_SUPPLY } from "./token.ts";

export const DepositLiquidity = async (
  creator: PublicKey,
  ammId: PublicKey,
  mint: PublicKey,
  id: string
): Promise<TransactionInstruction> => {
  const ammKey = await getAmmKey(ammId);
  const poolAuthority = await getPoolAuthority(ammKey, mint);
  const poolAccount = await getPoolKey(ammKey, mint);
  const poolAccountA = await getAssociatedTokenAddress(
    mint,
    poolAuthority,
    true
  );
  const depositorAccountA = await getAssociatedTokenAddress(
    mint,
    creator,
    true
  );

  return program.methods
    .depositLiquidity(TOTAL_SUPPLY, id)
    .accounts({
      payer: creator,
      pool: poolAccount,
      poolAuthority,
      mintA: mint,
      poolAccountA,
      depositorAccountA,
      depositor: creator,
    })
    .instruction();
};
