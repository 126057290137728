//const baseUrl = "http://localhost:5000/predicitonmarket/us-central1/api";
const baseUrl = "https://api-iyxgmmkr2q-uc.a.run.app";

const MemeUrl = `${baseUrl}/meme`;
const MemeByVideoUrl = `${baseUrl}/meme/video`;
const MemesUrl = `${baseUrl}/memes`;
const MemesByLatestUrl = `${baseUrl}/memes/latest`;
const MemesByChainLengthUrl = `${baseUrl}/memes/chainLength`;
const TokenHoldingsUrl = `${baseUrl}/tokens`;
const MemeMetadataUrl = `${baseUrl}/meme/metadata`;

export {
  MemeUrl,
  MemesUrl,
  MemeByVideoUrl,
  TokenHoldingsUrl,
  MemesByLatestUrl,
  MemeMetadataUrl,
  MemesByChainLengthUrl,
};
