import {
  MemeUrl,
  MemesUrl,
  MemeByVideoUrl,
  TokenHoldingsUrl,
  MemesByLatestUrl,
  MemeMetadataUrl,
  MemesByChainLengthUrl,
} from "./apiUrls";
import axios from "axios";

const addMeme = async (meme) => {
  const url = MemeUrl;
  return axios
    .post(url, meme)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Error adding meme", err);
      return err;
    });
};

const getMemes = async (meme) => {
  const url = MemesUrl;
  return axios
    .get(url, meme)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error fetching memes", err);
      return err;
    });
};

const getMeme = async (id) => {
  const url = MemeUrl + "/" + id;

  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error retrieving meme", err);
      return err;
    });
};

const getMemeByVideo = async (meme) => {
  const url = MemeByVideoUrl;
  return axios
    .post(url, meme)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Error retrieving meme by video url", err);
      return err;
    });
};

const getAllTokenHoldingsByWalletAddress = async (walletAddress) => {
  const url = TokenHoldingsUrl + "/" + walletAddress;
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error retrieving token holdings by wallet address", err);
      return err;
    });
};

const getLatestMemes = async () => {
  const url = MemesByLatestUrl;
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error retrieving latest memes", err);
      return err;
    });
};

const getLongestChainedMemes = async () => {
  const url = MemesByChainLengthUrl;
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error retrieving longest chained memes", err);
      return err;
    });
};

// good luck
const publishOffchainMetadata = async (metadata) => {
  const url = MemeMetadataUrl;
  return axios
    .post(url, metadata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error publishing metadata", err);
      return err;
    });
};

export {
  addMeme,
  getMemes,
  getMeme,
  getMemeByVideo,
  getAllTokenHoldingsByWalletAddress,
  getLatestMemes,
  publishOffchainMetadata,
  getLongestChainedMemes,
};
