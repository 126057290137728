export type OnchainMetadata = {
  name: string;
  symbol: string;
  uri: string;
};

export const OnchainMetadataFromToken = (
  name: string,
  symbol: string,
  uri: string
): OnchainMetadata => {
  return {
    name,
    symbol,
    uri,
  };
};

export type OffchainMetadataAttribute = {
  trait_type: string;
  value: string;
};

export type OffchainMetadataFile = {
  uri: string;
  type: string;
  cdn: boolean;
};

export type OffchainMetadataProperty = {
  files: OffchainMetadataFile[];
  category: string;
};

export type OffchainMetadata = {
  name: string;
  symbol: string;
  description: string;
  image: string;
  animation_url: string;
  external_url: string;
  attributes: OffchainMetadataAttribute[];
  properties: OffchainMetadataProperty[];
};

export const OffchainMetadataFromToken = (
  name: string,
  symbol: string,
  description: string,
  thumbnailUrl: string,
  videoUrl: string,
  videoPlayUrl: string
): OffchainMetadata => {
  return {
    name,
    symbol,
    description,
    image: thumbnailUrl,
    external_url: videoUrl,
    animation_url: videoPlayUrl,
    attributes: [],
    properties: [],
  };
};
