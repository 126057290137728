import React, { useState, useEffect } from "react";
import Tabs from "../components/Tabs";
import { useNavigate } from "react-router-dom";
import TokenDetailsComponent from "../components/TokenDetailsComponent";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import { getLatestMemes, getLongestChainedMemes } from "../backendApi/api";
import "../components/ChainGradient.css";

const FILTERS = {
  LATEST: "latest",
  MY_TOKENS: "my_tokens",
  LONGEST_CHAINED: "longest_chained",
};

const filterOptions = [
  { key: FILTERS.LONGEST_CHAINED, label: "Longest Chains" },
  { key: FILTERS.LATEST, label: "Latest Uploads" },
  { key: FILTERS.MY_TOKENS, label: "My Tokens" },
];

const TokensPage = ({
  tabs,
  activeTab,
  setActiveTab,
  tokenHoldings,
  isConnected,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tradeTab = "trade";

  useEffect(() => {
    setActiveTab(tradeTab);
  }, [setActiveTab]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchedTokens, setSearchedTokens] = useState([]);
  const [filteredTokens, setFilteredTokens] = useState([]);
  const [latestTokens, setLatestTokens] = useState([]);
  const [longestChainTokens, setLongestChainTokens] = useState([]);
  const [chainedLoading, setChainedLoading] = useState(true);
  const [latestLoading, setLatestLoading] = useState(true);

  useEffect(() => {
    setChainedLoading(true);
    getLongestChainedMemes()
      .then((data) => {
        setLongestChainTokens(data);
        setFilteredTokens(data);
        setChainedLoading(false);
      })
      .catch((err) => {
        console.log("error fetching longest chained tokens: ", err);
      });
  }, []);

  useEffect(() => {
    setLatestLoading(true);
    getLatestMemes()
      .then((data) => {
        setLatestTokens(data);
        setLatestLoading(false);
      })
      .catch((err) => {
        console.log("error fetching latest tokens: ", err);
      });
  }, []);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  const [searchLoading, setSearchLoading] = useState(false);

  const handleSearch = () => {
    setSearchLoading(true);
    const searched = filteredTokens.filter(
      (token) =>
        token.ticker.toLowerCase().includes(searchTerm.toLowerCase()) ||
        token.chainedMemeData.some((meme) =>
          meme.videoUrl.toLowerCase().includes(searchTerm.toLowerCase())
        ) ||
        token.tokenMintAddress.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchedTokens(searched);
    setSearchLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    if (searchTerm === "") {
      setSearchedTokens([]);
    }
  }, [searchTerm]);

  const [filterOption, setFilterOption] = useState(FILTERS.LONGEST_CHAINED);
  useEffect(() => {
    if (filterOption === FILTERS.LONGEST_CHAINED) {
      setFilteredTokens(longestChainTokens);
      setSearchTerm("");
    } else if (filterOption === FILTERS.LATEST) {
      setFilteredTokens(latestTokens);
      setSearchTerm("");
    } else if (filterOption === FILTERS.MY_TOKENS) {
      if (tokenHoldings) {
        setFilteredTokens(tokenHoldings);
      } else {
        setFilteredTokens([]);
      }
      setSearchTerm("");
    }
  }, [filterOption, tokenHoldings, longestChainTokens, latestTokens]);

  const navigate = useNavigate();
  const onClickTradeTabCallBack = (key) => {
    navigate(`/${key}`);
  };

  const onClickProfile = (tokenMintAddress) => {
    navigate(`/${tokenMintAddress}`);
  };

  const bgColor = "#1f1e24";
  const selectedTextColor = "#ffffff"; // Text color for selected button
  const unselectedTextColor = "#696969"; // Text color for unselected buttons

  return (
    <>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onClickCallBack={onClickTradeTabCallBack}
      />
      <div className='container mx-auto p-4'>
        {/* Search Bar */}
        <div className='mx-auto max-w-screen-md w-full'>
          <div
            className='mb-4 ml-1 mr-1 flex items-center '
            style={{ backgroundColor: bgColor, position: "relative" }}>
            <input
              type='text'
              placeholder='Token Address, Ticker, or TikTok URL'
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              className={`w-full p-2 rounded border text-black ${
                isMobile && "placeholder:text-sm"
              }`}
              style={{ paddingRight: "25px" }}
            />
            {searchTerm && (
              <span
                onClick={handleClear}
                style={{
                  position: "absolute",
                  right: "145px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "gray",
                  fontSize: "20px",
                }}>
                &times;
              </span>
            )}
            <Button
              variant='contained'
              startIcon={
                !isMobile &&
                !searchLoading && <SearchIcon style={{ color: "black" }} />
              }
              className='background-chain-gradient'
              sx={{
                marginLeft: "4px",
                padding: "8px",
                height: "42px",
                width: isMobile ? "" : "160px",
                cursor: "pointer",
                color: "black",
              }}
              onClick={handleSearch}>
              {searchLoading ? (
                <CircularProgress size={20} style={{ color: "black" }} />
              ) : isMobile ? (
                <SearchIcon style={{ color: "black" }} />
              ) : (
                "search"
              )}
            </Button>
          </div>
        </div>
        {/* Filter Buttons */}
        <div className='flex justify-around mb-4'>
          {filterOptions.map((filter) => (
            <button
              key={filter.key}
              className='filter-btn p-2 rounded'
              style={{
                backgroundColor: bgColor,
                color:
                  filterOption === filter.key
                    ? selectedTextColor
                    : unselectedTextColor,
              }}
              onClick={() => setFilterOption(filter.key)}>
              {filter.label}
            </button>
          ))}
        </div>
        {/* Tokens List */}
        {(filterOption === FILTERS.LONGEST_CHAINED && chainedLoading) ||
        (filterOption === FILTERS.LATEST && latestLoading) ? (
          <div className='mt-40 flex justify-center'>
            {/* <CircularProgress size={80} style={{ color: "#bfdbfe" }} /> */}
            <CircularProgress size={80} style={{ color: "white" }} />
          </div>
        ) : filterOption === FILTERS.MY_TOKENS &&
          (!isConnected || tokenHoldings.length === 0) ? (
          <p
            className={`${!isMobile && "ml-20"} mt-5 p-1`}
            style={{ color: "#9ca3af" }}>
            {" "}
            {isConnected
              ? "You currently do not possess any holdings. Search your favorite tokens and start trading now!"
              : "Connect your wallet to see your holdings!"}
          </p>
        ) : (
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
            {searchedTokens.length > 0
              ? searchedTokens.map((token, index) => (
                  <div
                    className={`p-2 flex flex-col items-left justify-center relative cursor-pointer transition duration-300 ease-in-out rounded-lg ${
                      !isMobile
                        ? "hover:bg-gray-700 transform hover:-translate-y-1 hover:scale-105"
                        : ""
                    }`}
                    style={isMobile ? { backgroundColor: bgColor } : {}}
                    onClick={() => onClickProfile(token.tokenMintAddress)}>
                    <TokenDetailsComponent
                      profile={token}
                      filter={filterOption}
                    />
                  </div>
                ))
              : filteredTokens &&
                filteredTokens.length > 0 &&
                filteredTokens.map((token, index) => (
                  <div
                    className={`p-2 flex flex-col items-left justify-center relative cursor-pointer transition duration-300 ease-in-out rounded-lg ${
                      !isMobile
                        ? "hover:bg-gray-700 transform hover:-translate-y-1 hover:scale-105"
                        : ""
                    }`}
                    style={isMobile ? { backgroundColor: bgColor } : {}}
                    onClick={() => onClickProfile(token.tokenMintAddress)}>
                    <TokenDetailsComponent
                      profile={token}
                      filter={filterOption}
                    />
                  </div>
                ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TokensPage;
