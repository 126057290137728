import { program, getAmmKey, ADMIN_KEY, AmmData } from "./util/anchor.ts";
import { TOTAL_SUPPLY } from "./token.ts";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";

export const FEE: number = 100; // bps
export const MAX_PER_WALLET = TOTAL_SUPPLY;

export const CreateAmm = async (
  creator: PublicKey,
  id: PublicKey
): Promise<TransactionInstruction> => {
  // ammKey PDA
  const ammKey = await getAmmKey(id);

  //create amm tx
  return program.methods
    .createAmm(id, FEE, MAX_PER_WALLET)
    .accounts({
      payer: creator,
      amm: ammKey,
      admin: ADMIN_KEY,
    })
    .instruction();
};

export const GetAmmAccount = async (ammId: PublicKey): Promise<AmmData> => {
  return program.account.amm.fetch(ammId);
};
