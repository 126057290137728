import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import "./ChainGradient.css";
import { useLocation } from "react-router-dom";

const InfoComponent = ({ isOpen, onClose }) => {
  const NUM_PAGES = 1;
  const START_PAGE = 1;

  const location = useLocation();
  const [firstTimeOpen, setFirstTimeOpen] = useState(
    location.pathname === "/" || location.pathname === "/home"
  );
  const [page, setPage] = useState(START_PAGE);
  const [hasNext, setHasNext] = useState(START_PAGE < NUM_PAGES);

  useEffect(() => {
    if (page >= NUM_PAGES) {
      setHasNext(false);
    }
  }, [page]);

  const formTitleStyle = {
    textAlign: "center",
    fontSize: "24px",
    margin: "20px 0 20px 0",
  };

  if (!isOpen) return null;

  return (
    <div
      className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'
      style={{ zIndex: 1001 }}
      onClick={() => {
        !firstTimeOpen && onClose();
      }}>
      <div
        className='rounded-lg p-6 w-96 shadow-lg animate-zoom-in'
        style={{ backgroundColor: "#1a191f" }}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <h2 class='text-chain-gradient' style={formTitleStyle}>
          How It Works
        </h2>
        <ol style={{ color: "#9ca3af" }}>
          <li>
            Clip is a token fair launch platform for TikToks. Follow Clip on{" "}
            <a
              href='https://x.com/clipdotmeme'
              className='text-white underline hover:font-bold'
              target='_blank'
              rel='noopener noreferrer'>
              twitter
            </a>
            .
          </li>
          <li>
            Each TikTok can be used only
            <strong style={{ color: "white" }}> once</strong>. A TikTok can be
            either <strong style={{ color: "white" }}> Minted</strong> as a new
            clip, or
            <strong style={{ color: "white" }}> Chained</strong> to an existing
            clip.
          </li>
          <li>
            A <span class='text-chain-gradient'>Clip Chain</span> is a
            collection of TikToks users link together to represent a viral song,
            trend or meme. TikToks in the same chain represent the same token.
            The longer the chain, the more attention a token will receive.
          </li>
          <li>
            Every time buy volume for a token hits a threshold, one percent of
            tokens in pool <span class='text-red-500'>burns</span> and price{" "}
            <strong style={{ color: "white" }}>goes up</strong>. A new video can
            also be <strong style={{ color: "white" }}>chained</strong> to that
            token.
          </li>
        </ol>
        <div className='mt-2 flex justify-end'>
          {hasNext ? (
            <button
              className='flex items-center justify-center underline underline-offset-1 text-white hover:text-gray-400 hover:font-bold'
              onClick={() => {
                setPage((prevPage) => prevPage + 1);
              }}>
              Next <ArrowForwardIcon />
            </button>
          ) : (
            <button
              className='flex items-center justify-center underline underline-offset-2 text-white hover:text-white-400 hover:font-bold'
              onClick={() => {
                onClose();
                setFirstTimeOpen(false);
                setPage(START_PAGE);
                setHasNext(START_PAGE < NUM_PAGES);
              }}>
              {firstTimeOpen ? "Ready to Clip" : "Close"}
              {/* {firstTimeOpen ? <ArrowForwardIcon /> : <CloseIcon />} */}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoComponent;
