import React, { useEffect, useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
  useWallet,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  UnsafeBurnerWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import { getAllTokenHoldingsByWalletAddress } from "../backendApi/api";
require("@solana/wallet-adapter-react-ui/styles.css");

const WalletContext = React.createContext();

function Wallet({ children, setTokenHoldings }) {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new UnsafeBurnerWalletAdapter(),
    ],
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <WalletContext.Provider value={{ setTokenHoldings }}>
            {children}
          </WalletContext.Provider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

function WalletStatus({ setTokenHoldings, setIsConnected }) {
  const { connected, publicKey } = useWallet();
  useEffect(() => {
    setIsConnected(connected);
  }, [connected, setIsConnected]);

  useEffect(() => {
    if (connected && publicKey) {
      const walletAddress = publicKey?.toBase58();
      getAllTokenHoldingsByWalletAddress(walletAddress).then((tokens) => {
        setTokenHoldings(tokens);
      });
    }
  }, [connected, publicKey, setTokenHoldings]);

  return (
    <div className='flex flex-col items-center justify-between'>
      <div className='flex space-x-4'>
        <WalletMultiButton />
      </div>
    </div>
  );
}

const useSolanaWallet = () => {
  return useWallet();
};

export { Wallet, WalletStatus, useSolanaWallet };
