import React, { useEffect, useState } from "react";
import TokenDetailsComponent from "./TokenDetailsComponent";
import { MIGRATION_CHAIN_COUNT } from "../util/util";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import "./ChainGradient.css";

const TokenInfoComponent = ({ profile, transactions, isMobile, isActive }) => {
  const [lastTransaction, setLastTransaction] = useState(null);
  useEffect(() => {
    if (transactions && transactions.length > 0) {
      setLastTransaction(transactions[0]);
    }
  }, [transactions]);

  const [cumulativeVolume, setCumulativeVolume] = useState(0);
  const [cumulativeVolumeThreshold, setCumulativeVolumeThreshold] = useState(0);
  const [buyVolumeNeeded, setBuyVolumeNeeded] = useState(0);
  const [buyProgression, setBuyProgression] = useState(0);
  useEffect(() => {
    if (lastTransaction) {
      const cumulativeVolumeNum =
        parseInt(lastTransaction.cumulativeVolume, 16) / LAMPORTS_PER_SOL;
      const cumulativeVolumeThresholdNum =
        parseInt(lastTransaction.cumulativeVolumeThreshold, 16) /
        LAMPORTS_PER_SOL;

      setCumulativeVolume(cumulativeVolumeNum.toFixed(2));
      setCumulativeVolumeThreshold(cumulativeVolumeThresholdNum.toFixed(2));
      setBuyVolumeNeeded(
        (cumulativeVolumeThresholdNum - cumulativeVolumeNum).toFixed(2)
      );
      setBuyProgression(
        ((cumulativeVolumeNum / cumulativeVolumeThresholdNum) * 100).toFixed(2)
      );
    }
  }, [lastTransaction]);

  const [chainCountProgressPercentage, setChainCountProgressPercentage] =
    useState(0);
  const [chainCount, setChainCount] = useState(0);
  useEffect(() => {
    setChainCount(
      profile?.chainedMemeIds?.length && profile?.chainedMemeIds?.length
    );
  }, [profile]);
  useEffect(() => {
    const percentage = (chainCount / MIGRATION_CHAIN_COUNT) * 100;
    setChainCountProgressPercentage(
      percentage > 100 ? 100 : percentage.toFixed(0)
    );
  }, [chainCount]);

  if (!isActive) {
    return null;
  }

  return (
    <div
      className={isMobile ? "rounded-lg shadow-md p-4 my-4" : "my-4"}
      style={{ backgroundColor: "#1f1e24" }}>
      <TokenDetailsComponent profile={profile} transactions={transactions} />
      <div className='mt-4 mb-4'>
        <p className='text-sm font-bold mb-2'>
          Meme Chain Count: {chainCount} / {MIGRATION_CHAIN_COUNT}
        </p>
        <div className='w-full bg-gray-200 rounded-full h-5 dark:bg-gray-700'>
          <div
            className='h-5 rounded-full background-chain-gradient'
            style={{
              width: `${chainCountProgressPercentage}%`,
            }}></div>
        </div>
      </div>
      <div className='mt-4 mb-4'>
        <p className='text-sm font-bold mb-2'>
          Burn Progression: {cumulativeVolume} / {cumulativeVolumeThreshold} Sol
        </p>
        <div className='w-full bg-gray-200 rounded-full h-5 dark:bg-gray-700'>
          <div
            className='bg-red-600 h-5 rounded-full'
            style={{
              width:
                buyProgression > 0 ? `${Math.max(buyProgression, 3)}%` : "0%", // Ensures a minimum width
            }}></div>
        </div>
      </div>
      <p className='text-sm text-gray-400'>
        In the current progression, another {buyVolumeNeeded} sol in buy volume
        is needed to <span class='text-red-500'>burn</span> tokens and chain a
        new video. Selling does not affect the progression. Sol buy volume
        needed for chaining doubles with each chain increment until it reaches
        32 sol. Once a total of {MIGRATION_CHAIN_COUNT} videos are chained, the
        chain threshold is met and trading fees reduces to 0.2%. Users can keep
        chaining videos and tokens in the pool will be burnt for every 32 sol of
        buy volume. Happy Chaining!
      </p>
    </div>
  );
};

export default TokenInfoComponent;
