import React, { useEffect, useState } from "react";
import Meme from "./MemeComponent";

function MemeFeed({ memes }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className='mb-8'>
      {/* Adjusted title with larger font and added left margin */}
      <div className='grid grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-2 md:gap-4 p-2'>
        {memes &&
          memes.length > 0 &&
          memes.map((meme) => (
            <Meme
              meme={meme}
              isMobile={isMobile}
              interval={(Math.random() * (5 - 2.5) + 2.5) * 1000}
            />
          ))}
      </div>
    </div>
  );
}

export default MemeFeed;
