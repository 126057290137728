import React, { useEffect, useState, useRef } from "react";
import { addMeme, getMemeByVideo } from "../backendApi/api";
import CircularProgress from "@mui/material/CircularProgress";
import TikTokVideoComponent from "./TikTokVideoComponent";
import SearchIcon from "@mui/icons-material/Search";
import "./ChainGradient.css";

const TITLE_MAX_LENGTH = 20;

const ChainMemeComponent = ({
  isOpen,
  onClose,
  onBuyAndChain,
  autoFillTitle,
  isBuyAndChain,
  chainError,
  chainLoading,
}) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [tickerImgUrl, setTickerImgUrl] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [videoPlayUrl, setVideoPlayUrl] = useState("");
  const [hasCheckedVideoUrl, setHasCheckedVideoUrl] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState(autoFillTitle);
  const [loading, setLoading] = useState(false);

  const handleVideoUrlChange = (event) => {
    setErrorMessage("");
    setVideoUrl(event.target.value);
  };

  useEffect(() => {
    setTitle(autoFillTitle);
  }, [autoFillTitle]);

  useEffect(() => {
    setErrorMessage(chainError);
  }, [chainError]);

  useEffect(() => {
    setLoading(chainLoading);
  }, [chainLoading]);

  const handleSearchVideoUrl = async () => {
    setErrorMessage("");
    setSearchLoading(true);
    if (videoUrl === "") {
      setErrorMessage("No TikTok URL given.");
    } else {
      const meme = { videoUrl: videoUrl };
      const res = await getMemeByVideo(meme);

      if (res.status === 200) {
        setMessage("Congrats! This video has not been used.");
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setTickerImgUrl(videoUrl);
        setHasCheckedVideoUrl(true);
        setVideoPlayUrl(res.data.videoPlayUrl);
        setMessage("");
      } else if (res.response.status === 400) {
        const memeId = res.response.data.id;
        if (memeId) {
          setErrorMessage(
            "Oops, this video has already been used. Please choose another video."
          );
        } else {
          setErrorMessage(res.response.data.error);
        }
      } else {
        setErrorMessage(res.response.data.error);
      }
    }
    setSearchLoading(false);
  };

  const handleTitleChange = (event) => {
    setErrorMessage("");
    setTitle(event.target.value);
  };

  const resetStates = () => {
    setVideoUrl("");
    setTickerImgUrl("");
    setSearchLoading(false);
    setErrorMessage("");
    setVideoPlayUrl("");
    setHasCheckedVideoUrl(false);
    setMessage("");
    setTitle(autoFillTitle);
    setLoading(false);
  };

  const handleSubmit = async (event) => {
    setErrorMessage("");
    setLoading(true);
    event.preventDefault(); // Prevent the default form submission behavior

    if (title === "") {
      setLoading(false);
      setErrorMessage("Please enter a name for this meme.");
      return;
    }

    if (title.length > TITLE_MAX_LENGTH) {
      setLoading(false);
      setErrorMessage(
        `Name cannot be longer than ${TITLE_MAX_LENGTH} characters.`
      );
      return;
    }

    const meme = {
      ticker: "",
      title: title,
      videoUrl: tickerImgUrl,
      description: "",
      isOriginalMeme: false,
    };

    const res = await addMeme(meme);
    console.log("Res response: ", res);
    setLoading(false);

    if (res.status === 200) {
      const id = res.data.id;
      onBuyAndChain(id);
    } else if (res.response.status === 409) {
      setErrorMessage(
        "Oops, someone is meming or already memed this video. Start over and choose another video instead."
      );
      await new Promise((resolve) => setTimeout(resolve, 2000));
      resetStates();
    } else {
      setErrorMessage(res.response.data.error);
    }
  };

  const formTitleStyle = {
    textAlign: "center",
    fontSize: "24px",
    margin: "20px 0 20px 0",
  };

  const formGroupStyle = {
    marginTop: "10px",
    marginBottom: "10px",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    color: "#9ca3af",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    color: "black",
  };

  if (!isOpen) return null;

  return (
    <div
      className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'
      style={{ zIndex: 1001 }}
      onClick={() => {
        !hasCheckedVideoUrl && !isBuyAndChain && onClose();
      }}>
      <div
        className='rounded-lg p-6 w-96 shadow-lg animate-zoom-in'
        style={{ backgroundColor: "#1a191f" }}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {hasCheckedVideoUrl ? (
          <>
            <div className='flex items-center justify-center cursor-pointer'>
              <TikTokVideoComponent videoPlayUrl={videoPlayUrl} />
            </div>
            <form onSubmit={handleSubmit}>
              <div style={formGroupStyle}>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>Name:</label>
                  <input
                    type='text'
                    name='title'
                    style={inputStyle}
                    value={title}
                    onChange={handleTitleChange}
                  />
                </div>
              </div>
              {errorMessage && (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  {errorMessage}
                </div>
              )}
              <div className='mt-4 flex space-x-4 justify-center'>
                <button
                  type='submit'
                  style={{
                    width: "150px",
                    height: "42px",
                  }}
                  className='px-4 py-2 rounded background-chain-gradient font-bold text-black transition cursor-pointer flex items-center justify-center'>
                  {loading ? (
                    <CircularProgress size={20} style={{ color: "black" }} />
                  ) : isBuyAndChain ? (
                    "Buy and Chain"
                  ) : (
                    "Chain Video"
                  )}
                </button>
                <button
                  style={{ height: "42px" }}
                  className='bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500 transition cursor-pointer flex items-center justify-center'
                  onClick={onClose}>
                  Cancel
                </button>
              </div>
            </form>
          </>
        ) : (
          <>
            <h2 style={formTitleStyle}>Chain TikTok Meme</h2>
            <p className='mb-4' style={{ color: "#9ca3af" }}>
              You can now chain a new video to this token! Choose a TikTok video
              that's related to the ones in the existing chain, perhaps created
              by the same author or similar in songs and trends. Input your
              desired new TikTok video url below.
            </p>
            <div style={formGroupStyle}>
              <div className='mb-1 flex items-center '>
                <input
                  type='text'
                  name='videoUrl'
                  style={inputStyle}
                  value={videoUrl}
                  placeholder='TikTok Video URL'
                  onChange={handleVideoUrlChange}
                />
                <button
                  className='background-chain-gradient px-2 py-2 ml-2 rounded text-black transition flex items-center justify-center cursor-pointer'
                  onClick={handleSearchVideoUrl}
                  style={{ height: "42px", minWidth: "42px" }}>
                  {searchLoading ? (
                    <CircularProgress size={20} style={{ color: "black" }} />
                  ) : (
                    <SearchIcon style={{ color: "black" }} />
                  )}
                </button>
              </div>
              {errorMessage && (
                <div style={{ color: "red" }}>{errorMessage}</div>
              )}
              {message && <p className='text-yellow-300'> {message} </p>}
            </div>
            {isBuyAndChain && (
              <div className='mt-4 space-x-4 flex justify-center'>
                <button
                  className='bg-yellow-300 text-black px-4 py-2 rounded hover:bg-yellow-500 transition cursor-pointer'
                  onClick={() => onBuyAndChain("")}>
                  Buy without Chaining
                </button>
                <button
                  className='bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500 transition cursor-pointer'
                  onClick={onClose}>
                  Cancel
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChainMemeComponent;
