import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { Wallet, WalletStatus } from "./components/WalletComponent";

import BottomNavBar from "./components/BottomNavBar"; // Import the BottomNavBar component

import "./theme.css"; // Adjust the path based on your project structure
import BuySellPage from "./pages/BuySellPage";
import MemesPage from "./pages/MemesPage";
import { getMeme, getMemes } from "./backendApi/api";
import CreatePage from "./pages/CreatePage";
import TokensPage from "./pages/TokensPage";
import LogoComponent from "./components/LogoComponent";
import InfoComponent from "./components/InfoComponent";
import HelpIcon from "@mui/icons-material/Help";
import { IconButton } from "@mui/material";
import { SOCKET_SERVER_URL } from "./util/util";
import NotificationComponent from "./components/NotificationComponent";

const App = () => {
  const [mainActiveTab, setMainActiveTab] = useState("");
  const mainTabs = [
    { key: "home", label: "For You" },
    { key: "create", label: "Create" },
    { key: "trade", label: "Trade" },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isConnected, setIsConnected] = useState(false);

  const [memes, setMemes] = useState([]);
  const memesRef = useRef(memes);

  useEffect(() => {
    memesRef.current = memes;
  }, [memes]);

  useEffect(() => {
    getMemes()
      .then((data) => {
        setMemes(data);
      })
      .catch((err) => {
        console.log("error fetching memes: ", err);
      });
  }, []);

  const [tokenHoldings, setTokenHoldings] = useState([]);

  const location = useLocation();
  const [infoOpen, setInfoOpen] = useState(
    location.pathname === "/" || location.pathname === "/home"
  );
  const handleOpen = () => setInfoOpen(true);
  const handleClose = () => setInfoOpen(false);

  const [notification, setNotification] = useState({
    ticker: "",
    tx: {},
    isVisible: false,
  });

  useEffect(() => {
    if (notification && notification.isVisible) {
      const timer = setTimeout(() => {
        setNotification({ ...notification, isVisible: false });
      }, 2000); // Hide notification after 2 seconds

      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    const ws = new WebSocket(SOCKET_SERVER_URL);

    ws.addEventListener("open", () => {
      console.log("Connected to WebSocket server");

      // Register the client with the WebSocket server
      ws.send(JSON.stringify({ type: "register_all" }));
    });

    ws.addEventListener("message", async (message) => {
      try {
        const txs = JSON.parse(message.data);
        const lastTx = txs[0];
        const tokenList = memesRef.current.filter(
          (meme) => meme.tokenMintAddress === lastTx.tokenMintAddr
        );
        if (tokenList.length === 0) {
          const token = await getMeme(lastTx.tokenMintAddr);
          if (token.id) {
            setNotification({
              ticker: token.ticker,
              tx: lastTx,
              isVisible: true,
            });
          } else {
            setNotification({
              ticker: "",
              tx: lastTx,
              isVisible: true,
            });
          }
        } else {
          setNotification({
            ticker: tokenList[0].ticker,
            tx: lastTx,
            isVisible: true,
          });
        }
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    });

    ws.addEventListener("close", () => {
      console.log("Disconnected from WebSocket server");
    });

    ws.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
    });

    return () => {
      ws.close();
    };
  }, []);

  return (
    <Wallet setTokenHoldings={setTokenHoldings}>
      <div>
        {(!isMobile || (isMobile && !isConnected)) && (
          <div className='mb-8 pb-8'>
            <div
              className='absolute top-0 right-0 m-4'
              style={{ zIndex: 1000 }}>
              <WalletStatus
                setTokenHoldings={setTokenHoldings}
                setIsConnected={setIsConnected}
              />
            </div>
            <div className='absolute top-0 left-0 m-4' style={{ zIndex: 1000 }}>
              <LogoComponent />
            </div>
          </div>
        )}
        <IconButton
          onClick={handleOpen}
          disableRipple
          sx={{
            position: "fixed",
            bottom: "16px",
            right: "16px",
            zIndex: 1000,
            width: 50,
            height: 50,
            backgroundColor: "#c084fc",
            "& .MuiSvgIcon-root": {
              fontSize: 45,
            },
            "&:hover": {
              backgroundColor: isMobile ? "#c084fc" : "#9333ea",
            },
            "&:active": {
              backgroundColor: "#9333ea",
            },
          }}>
          <HelpIcon
            className={`text-purple-600 ${
              !isMobile && "hover:text-purple-400"
            } active:text-purple-400`}
          />
        </IconButton>
        <InfoComponent isOpen={infoOpen} onClose={handleClose} />
        <NotificationComponent
          ticker={notification.ticker}
          tx={notification.tx}
          isVisible={notification.isVisible}
        />
        <div>
          <Routes>
            <Route
              path='home'
              element={
                <MemesPage
                  memes={memes}
                  tabs={mainTabs}
                  activeTab={mainActiveTab}
                  setActiveTab={setMainActiveTab}
                />
              }
            />
            <Route
              path='create'
              element={
                <CreatePage
                  tabs={mainTabs}
                  activeTab={mainActiveTab}
                  setActiveTab={setMainActiveTab}
                />
              }
            />
            <Route
              path='trade'
              element={
                <TokensPage
                  tabs={mainTabs}
                  activeTab={mainActiveTab}
                  setActiveTab={setMainActiveTab}
                  tokenHoldings={tokenHoldings}
                  isConnected={isConnected}
                />
              }
            />
            <Route path='create' element={<CreatePage />} />
            <Route
              path='/:tokenMintAddress'
              element={<BuySellPage tokenHoldings={tokenHoldings} />}
            />
            <Route path='*' element={<Navigate replace to='/home' />} />
          </Routes>
        </div>
        {/* <BottomNavBar /> */}
      </div>
    </Wallet>
  );
};

export default App;
