import React, { useRef, useEffect, useState } from "react";

// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { IconButton } from "@mui/material";

// !!! Pass in videoplayurls as an array to view the chained videos
function TikTokVideoComponent({ videoPlayUrl }) {
  const [isLoading, setIsLoading] = useState(true);
  // const iframeRef = useRef(null);

  // const [isPlaying, setIsPlaying] = useState(false);
  // const handlePlayButtonClick = () => {
  //   setIsPlaying(true);
  // };

  // const iframeUrlRef = useRef(videoPlayUrl);

  // const { id, thumbnailUrl, ticker, description, videoPlayUrl } = meme;

  const handleIframeLoad = () => {
    // console.log("videorul: ", iframeUrlRef.current);
    setIsLoading(false);
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (isLoading) {
  //       iframeUrlRef.current = videoPlayUrl;
  //     }
  //   }, 3000); // 3000ms delay

  //   return () => clearInterval(intervalId);
  // }, [isLoading, videoPlayUrl]);

  useEffect(() => {
    // console.log("here: ", videoPlayUrl);
    // Update the iframe URL ref if the videoPlayUrl changes
    // if (iframeUrlRef.current !== videoPlayUrl) {
    //   iframeUrlRef.current = videoPlayUrl;
    //   setIsLoading(true); // Set loading state when URL changes
    //   console.log("isloading true");
    // }
    setIsLoading(true);
  }, [videoPlayUrl]);

  // const VideoComponent = () => {
  return (
    // <div className='w-[400px] h-[400px] md:w-80 md:h-100 p-1.5 relative transition-transform transform md:hover:scale-105 '>
    <div className='flex items-center justify-center w-[350px] h-[550px] p-1.5 relative transition-transform transform md:hover:scale-105'>
      <div className='relative w-full h-full overflow-hidden rounded-sm '>
        {/* <div className='w-60 h-80 p-1.5 md:mt-4 relative transition-transform transform md:hover:scale-105 '>
     <div className='relative w-full h-full overflow-hidden rounded-sm'> */}
        {/* {!isPlaying ? (
          <div className='relative w-full h-full'>
            <img
              src={thumbnailUrl}
              alt={ticker}
              className='w-full h-full object-cover rounded-lg shadow-lg hover:shadow-xl' // Adjusted to cover the full dimensions
            />
            <div
              className='absolute top-0 left-0 w-full h-full flex items-center justify-center cursor-pointer'
              onClick={handlePlayButtonClick}>
              <IconButton style={{ fontSize: 60 }}>
                <PlayCircleFilledWhiteIcon
                  style={{ fontSize: 60, color: "white", opacity: 0.75 }}
                />
              </IconButton>
            </div>
          </div>
        ) : ( */}
        <>
          {isLoading && (
            <div className='absolute top-0 left-0 w-full h-full bg-gray-200 flex items-center justify-center'>
              <div className='animate-pulse bg-gray-300 w-4/5 h-4/5'></div>
            </div>
          )}
          <iframe
            // "https://www.youtube.com/embed/w4sEBZo3MJI"
            // ref={iframeRef}
            key={videoPlayUrl}
            title={videoPlayUrl}
            // src={iframeUrlRef.current}
            src={videoPlayUrl}
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            className='absolute top-0 left-0 w-full h-full'
            onLoad={handleIframeLoad}></iframe>
        </>
        {/* )} */}
      </div>
    </div>
  );
  // };

  // return isMobile ? (
  //   <div className='flex items-center justify-center cursor-pointer'>
  //     <VideoComponent />
  //   </div>
  // ) : (
  //   <div className='flex flex-col items-center cursor-pointer w-full'>
  //     <VideoComponent />
  //   </div>
  // );
}

// <div className='relative w-full h-full overflow-hidden rounded-sm'>
//   {isLoading && (
//     <div className='absolute top-0 left-0 w-full h-full bg-gray-200 flex items-center justify-center'>
//       <div className='animate-pulse bg-gray-300 w-3/4 h-3/4'></div>
//     </div>
//   )}
//   <iframe
//     ref={iframeRef}
//     title={title}
//     src={videoPlayUrl}
//     frameBorder='0'
//     allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
//     allowFullScreen
//     className='absolute top-0 left-0 w-full h-full'
//     onLoad={handleIframeLoad}></iframe>
// </div>

export default TikTokVideoComponent;
