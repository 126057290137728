import { IdlAccounts, Program } from "@coral-xyz/anchor";
import { IDL, Pumpy } from "../idl/pumpy.ts";
import { clusterApiUrl, Connection, PublicKey } from "@solana/web3.js";

// the contract publisher public key
export const ADMIN_KEY: PublicKey = new PublicKey(
  "CCCkDbf7o1uS797HA1USFHjHL7daATDdckRd9fnSaM3o"
);

export const TOKEN_METADATA_PROGRAM: PublicKey = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

// TODO: we should find a better way to update these when we deploy
const programId: PublicKey = new PublicKey(
  "CSLr1JoXBTRq2XQJ1Z3UNJF97s9LiWD4uZFSFkxFEpic"
);

// setup the anchor provider
const tsConnection: Connection = new Connection(
  clusterApiUrl("devnet"),
  "confirmed"
);

// export the pumpy program
export const program = new Program<Pumpy>(IDL, programId, {
  connection: tsConnection,
});

// Derive a PDA for the pumpy account, using "pumpy" as the seed.
export const [pumpyPDA] = PublicKey.findProgramAddressSync(
  [Buffer.from("pumpy")],
  program.programId
);

// get the amm PDA by its unique id, or create it if it doesn't exist
export const getAmmKey = async (id: PublicKey): Promise<PublicKey> => {
  return PublicKey.findProgramAddressSync(
    [id.toBuffer()],
    program.programId
  )[0];
};

export const getTokenMetadata = async (mint: PublicKey): Promise<PublicKey> => {
  // PDA for metadata account
  const [metadataPDA, _] = PublicKey.findProgramAddressSync(
    [
      Buffer.from("metadata"),
      TOKEN_METADATA_PROGRAM.toBuffer(),
      mint.toBuffer(),
    ],
    TOKEN_METADATA_PROGRAM // The public key of the token metadata program
  );
  return metadataPDA;
};

export const getPoolAuthority = async (
  ammKey: PublicKey,
  mint: PublicKey
): Promise<PublicKey> => {
  return PublicKey.findProgramAddressSync(
    [ammKey.toBuffer(), mint.toBuffer(), Buffer.from("authority")],
    program.programId
  )[0];
};

export const getPoolKey = async (
  ammKey: PublicKey,
  mint: PublicKey
): Promise<PublicKey> => {
  return PublicKey.findProgramAddressSync(
    [ammKey.toBuffer(), mint.toBuffer()],
    program.programId
  )[0];
};

export const getMintLiquidityKey = async (
  ammKey: PublicKey,
  mint: PublicKey
): Promise<PublicKey> => {
  return PublicKey.findProgramAddressSync(
    [ammKey.toBuffer(), mint.toBuffer(), Buffer.from("liquidity")],
    program.programId
  )[0];
};

// Define TypeScript types for the program data structures based on the IDL.
export type AmmData = IdlAccounts<Pumpy>["amm"];
export type PoolData = IdlAccounts<Pumpy>["pool"];
