import React, { useState, useEffect } from "react";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

function TikTokThumbnailComponent({ id, thumbnailInfo, index, isMobile }) {
  const [loading, setLoading] = useState(true);
  const { thumbnailUrl, title } = thumbnailInfo;

  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/${id}`, {
      state: { chainedMemeIndex: index },
    });
  };

  useEffect(() => {
    const img = new Image();
    img.src = thumbnailUrl;
    img.onload = () => setLoading(false);
  }, [thumbnailUrl]);

  const shimmerStyle = {
    position: "relative",
    overflow: "hidden",
    background:
      "linear-gradient(90deg, rgba(42,42,46,0) 0%, rgba(42,42,46,0.2) 20%, rgba(42,42,46,0.4) 60%, rgba(42,42,46,0) 100%)",
    animation: "shimmer 1.5s infinite",
  };

  return (
    <div className='md:p-2 mt-2 md:mt-4 flex flex-col' onClick={onClick}>
      <div className='md:p-2 overflow-hidden rounded-sm'>
        {loading ? (
          <div
            className='w-full h-full object-cover rounded-lg'
            style={{ ...shimmerStyle, width: "90%", aspectRatio: "2/3" }}></div>
        ) : (
          <>
            <img
              src={thumbnailUrl}
              alt={title}
              className='w-full h-full object-cover rounded-lg' // Adjusted to cover the full dimensions
              style={{ width: "90%", aspectRatio: "2/3" }}
            />
            <div className='absolute bottom-6 left-0 w-full h-full flex items-center justify-center cursor-pointer'>
              <IconButton style={{ fontSize: 60 }}>
                <PlayCircleFilledWhiteIcon
                  style={{ fontSize: 60, color: "white", opacity: 0.75 }}
                />
              </IconButton>
            </div>
          </>
        )}
        <div className={isMobile ? "mt-2 pb-8" : "mt-2 pb-4"}>
          <h2 className={isMobile ? "text-sm font-base" : "text-lg font-base"}>
            {title}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default TikTokThumbnailComponent;
