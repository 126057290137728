import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TikTokThumbnailComponent from "./TikTokThumnailComponent";

function TikTokThumbnailCarousel({ meme, isMobile, interval }) {
  const [thumbnailsInfo, setThumbnailsInfo] = useState([]);

  useEffect(() => {
    if (meme && meme.chainedMemeData) {
      setThumbnailsInfo(meme.chainedMemeData);
    }
  }, [meme]);

  return (
    <div className='w-full' onClick={(e) => e.stopPropagation()}>
      {thumbnailsInfo.length > 0 && (
        <Carousel
          showArrows={isMobile ? false : true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={true}
          autoPlay={true}
          interval={interval}
          swipeable={!isMobile}>
          {thumbnailsInfo.map((info, index) => (
            <TikTokThumbnailComponent
              id={meme.tokenMintAddress}
              index={index}
              thumbnailInfo={info}
              isMobile={isMobile}
            />
          ))}
        </Carousel>
      )}
    </div>
  );
}

export default TikTokThumbnailCarousel;
