// Tab.js
import React from "react";

const Tab = ({ label, isActive, onClick }) => {
  return (
    <button
      className={`relative px-4 md:px-8 py-3 text-base font-semibold ${
        isActive ? "text-white" : "text-gray-400"
      } hover:text-white`}
      onClick={onClick}>
      {label}
      {isActive && (
        <span className='absolute bottom-0 left-1/2 transform -translate-x-1/2 w-3/5 h-1 bg-blue-200'></span>
      )}
    </button>
  );
};

export default Tab;
