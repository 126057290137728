import {
  program,
  getTokenMetadata,
  TOKEN_METADATA_PROGRAM,
} from "./util/anchor.ts";
import { OnchainMetadata } from "./tokenMetadata.ts";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { BN } from "@coral-xyz/anchor";

export const MINTED_AMOUNT: number = 1000000000; // 1b
export const DECIMALS: number = 9;
export const TOTAL_SUPPLY: BN = new BN(MINTED_AMOUNT).mul(
  new BN(10 ** DECIMALS)
);

export const CreateToken = async (
  creator: PublicKey,
  mint: PublicKey,
  metadata: OnchainMetadata
): Promise<TransactionInstruction> => {
  // get the associated token account
  const associatedTokenAccount = await getAssociatedTokenAddress(
    mint,
    creator,
    false
  );

  // get the metadata PDA for the mint
  const metadataPDA = await getTokenMetadata(mint);

  return program.methods
    .createTokenMint(
      DECIMALS,
      metadata.name,
      metadata.symbol,
      metadata.uri,
      TOTAL_SUPPLY
    )
    .accounts({
      payer: creator,
      mintAccount: mint,
      associatedTokenAccount,
      metadataAccount: metadataPDA,
      tokenMetadataProgram: TOKEN_METADATA_PROGRAM,
    })
    .instruction();
};
