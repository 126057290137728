import React, { useEffect, useState } from "react";
import {
  tokenAmountReceivedFromPool,
  solAmountReceivedFromPool,
  getUpdatedTokenHoldings,
} from "../util/util";
import BuySellProfileComponent from "./BuySellProfileComponent";
import TokenInfoComponent from "./TokenInfoComponent";
import ChainMemeComponent from "./ChainMemeComponent";
import {
  Transaction,
  Connection,
  PublicKey,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { Buy, Sell } from "../contracts/trade.ts";
import { Chain } from "../contracts/chain.ts";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import BN from "bn.js";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import "./ChainGradient.css";
import { SOCKET_SERVER_URL } from "../util/util";

const BUY_INCREMENT = 0.1;
const SELL_INCREMENT = 100000000;
const SLIPPAGE = 0.01;

const BuySellComponent = ({
  profile,
  fetchProfile,
  isMobile,
  tokenHoldings,
  transactions,
  chainedMemeIndex,
  oldTransactions,
  setActiveTab,
  tabs,
  isActive,
}) => {
  const { connected, publicKey, signTransaction } = useWallet();
  const { connection } = useConnection();

  const [solBalance, setSolBalance] = useState(0);

  const [displayMemeIndex, setDisplayMemeIndex] = useState(0);

  const updateDisplayMemeIndex = (index) => {
    setDisplayMemeIndex(index);
  };

  useEffect(() => {
    if (chainedMemeIndex) {
      setDisplayMemeIndex(chainedMemeIndex);
    }
  }, [chainedMemeIndex]);

  useEffect(() => {
    if (connected && publicKey) {
      connection.getBalance(publicKey).then((balance) => {
        balance ? setSolBalance(balance / LAMPORTS_PER_SOL) : setSolBalance(0);
      });
    }
  }, [connected, connection, publicKey, setSolBalance]);

  const updateSolBalance = () => {
    if (connected && publicKey) {
      connection.getBalance(publicKey).then((balance) => {
        balance ? setSolBalance(balance / LAMPORTS_PER_SOL) : setSolBalance(0);
      });
    }
  };

  const [chainingEnabled, setChainingEnabled] = useState(false);
  const [key, setKey] = useState(0);
  const [chainError, setChainError] = useState("");
  const [chainLoading, setChainLoading] = useState(false);

  useEffect(() => {
    if (chainLoading) {
      const timer = setTimeout(() => {
        window.location.reload();
      }, 10000);

      // Clean up the timer if the component unmounts or if loading changes
      return () => clearTimeout(timer);
    }
  }, [chainLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id, bondingCurveAddress, ticker, title } = profile;

  const [holdings, setHoldings] = useState(0);
  const [lastUpdatedHoldingsTimestamp, setLastUpdatedHoldingsTimestamp] =
    useState(0);
  const [isBuy, setIsBuy] = useState(false);
  useEffect(() => {
    setLastUpdatedHoldingsTimestamp(Date.now());
    if (tokenHoldings && tokenHoldings.length > 0) {
      const holding = tokenHoldings.find(
        (token) => token.tokenMintAddress === id
      );
      if (holding) {
        setHoldings(Number(holding.amount));
      } else {
        setHoldings(0);
      }
    } else {
      setHoldings(0);
    }
  }, [id, setLastUpdatedHoldingsTimestamp, tokenHoldings]);

  useEffect(() => {
    if (
      holdings >= 0 &&
      transactions &&
      transactions.length > 0 &&
      oldTransactions &&
      oldTransactions.length > 0
    ) {
      const newTxs = transactions.filter(
        (tx) => !oldTransactions.some((oldTx) => oldTx.hash === tx.hash)
      );
      let newHoldings = holdings;
      let newTimestamp = lastUpdatedHoldingsTimestamp;
      for (let i = 0; i < newTxs.length; i++) {
        const tx = newTxs[i];
        const ts = tx.timestamp;
        if (
          ts > lastUpdatedHoldingsTimestamp &&
          tx.trader === publicKey?.toBase58()
        ) {
          newHoldings = getUpdatedTokenHoldings(newHoldings, tx);
          if (ts > newTimestamp) {
            newTimestamp = ts;
          }
        }
      }
      if (newHoldings !== holdings) {
        setHoldings(newHoldings);
      }
      if (newTimestamp !== lastUpdatedHoldingsTimestamp) {
        setLastUpdatedHoldingsTimestamp(newTimestamp);
      }
    }
  }, [
    holdings,
    lastUpdatedHoldingsTimestamp,
    publicKey,
    transactions,
    oldTransactions,
  ]);

  const [buyAmount, setBuyAmount] = useState(0);
  const handleBuyAmount = (amount) => {
    if (amount > 0) {
      const parsedAmount = parseFloat(amount.toFixed(3));
      setBuyAmount(parsedAmount);
    } else {
      setBuyAmount(0);
    }
  };

  const [tokenAmountReceived, setTokenAmountReceived] = useState(0);
  useEffect(() => {
    if (transactions && transactions.length > 0) {
      if (buyAmount > 0) {
        const lastTransaction = transactions[0];
        const outAmount = tokenAmountReceivedFromPool(
          lastTransaction.tokenInPool,
          lastTransaction.solInPool,
          buyAmount,
          LAMPORTS_PER_SOL
        );
        setTokenAmountReceived(outAmount);
      } else {
        setTokenAmountReceived(0);
      }
    }
  }, [transactions, buyAmount]);

  const sendBuyTx = async (userPKey, tokenAddress, ammKey) => {
    // manually specify connection until we can troubleshoot wallet provider
    const c = new Connection("https://api.devnet.solana.com");
    // latest blockhash and fee payer are required for partially signed tx
    const latestBlockhash = await c.getLatestBlockhash();
    const feePayer = userPKey;
    // tx wrapper for instructions
    const tx = new Transaction();

    // create token buy tx
    console.log(
      "tokenAmountReceived * LAMPORTS_PER_SOL * (1 - SLIPPAGE): ",
      tokenAmountReceived * LAMPORTS_PER_SOL * (1 - SLIPPAGE)
    );
    const tokenBuyInstruction = await Buy(
      userPKey,
      new PublicKey(ammKey),
      new PublicKey(tokenAddress),
      new BN(buyAmount * LAMPORTS_PER_SOL), // note that buyamount can be decimals
      // new BN(
      //   Math.trunc(tokenAmountReceived * LAMPORTS_PER_SOL * (1 - SLIPPAGE))
      // )
      new BN(tokenAmountReceived).mul(new BN(LAMPORTS_PER_SOL * (1 - SLIPPAGE)))
    );

    tx.add(tokenBuyInstruction);

    // add required metadata
    tx.recentBlockhash = latestBlockhash.blockhash;
    tx.feePayer = feePayer;
    // sign with user wallet
    const signedTx = await signTransaction(tx);

    // send tx
    // console.log("serialized tx output for tx inspector:");
    // console.log(tx.serializeMessage().toString("base64"));
    // console.log("visit https://solana.fm/inspector and paste the output");
    return c.sendRawTransaction(signedTx.serialize());
  };

  const onClickBuy = async () => {
    if (!publicKey) {
      alert("Please connect your wallet first!");
    } else if (buyAmount === "" || Number(buyAmount) === 0) {
      alert("Please enter an amount that you want to buy!");
    } else {
      if (buyAmount >= buyVolumeNeeded) {
        setChainingEnabled(true);
      } else {
        try {
          const res = await sendBuyTx(publicKey, id, bondingCurveAddress);
          console.log("Res Buy Tx: ", res);
          if (isMobile) {
            setActiveTab(tabs[3].key);
          } else {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            updateSolBalance();
          }
        } catch (error) {
          console.log("Buy error:", error);
        }
      }
    }
  };

  const handleCloseModal = () => {
    setChainingEnabled(false);
    setKey((prevKey) => prevKey + 1);
  };

  const handleChainResult = (docId) => {
    const ws = new WebSocket(SOCKET_SERVER_URL);

    ws.addEventListener("open", () => {
      console.log("Connected to WebSocket server");

      // Register the client with the WebSocket server
      ws.send(JSON.stringify({ type: "register", token: docId }));
    });

    ws.addEventListener("message", async (message) => {
      try {
        const res = JSON.parse(message.data);

        ws.close();

        setChainLoading(false);

        if (res.status === 200) {
          fetchProfile(res.id);
          setDisplayMemeIndex(res.chainedMemeIndex);
          handleCloseModal();
        } else {
          setChainError(res.error);
          await new Promise((resolve) => setTimeout(resolve, 2000));
          handleCloseModal();
        }
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    });

    ws.addEventListener("close", () => {
      console.log("Disconnected from WebSocket server");
    });

    ws.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
    });
  };

  const onBuyAndChainMeme = async (docId) => {
    const sendChainTx = async (userPKey, tokenAddress, ammKey, doc_id) => {
      // manually specify connection until we can troubleshoot wallet provider
      const c = new Connection("https://api.devnet.solana.com");
      // latest blockhash and fee payer are required for partially signed tx
      const latestBlockhash = await c.getLatestBlockhash();
      const feePayer = userPKey;
      // tx wrapper for instructions
      const tx = new Transaction();

      const chainMemeInstruction = await Chain(
        userPKey,
        new PublicKey(ammKey),
        new PublicKey(tokenAddress),
        doc_id
      );

      tx.add(chainMemeInstruction);

      // add required metadata
      tx.recentBlockhash = latestBlockhash.blockhash;
      tx.feePayer = feePayer;
      // sign with user wallet
      const signedTx = await signTransaction(tx);

      // send tx
      return c.sendRawTransaction(signedTx.serialize());
    };
    const sendBuyAndChainTx = async (
      userPKey,
      tokenAddress,
      ammKey,
      doc_id
    ) => {
      // manually specify connection until we can troubleshoot wallet provider
      const c = new Connection("https://api.devnet.solana.com");
      // latest blockhash and fee payer are required for partially signed tx
      const latestBlockhash = await c.getLatestBlockhash();
      const feePayer = userPKey;
      // tx wrapper for instructions
      const tx = new Transaction();

      // create token buy tx
      const tokenBuyInstruction = await Buy(
        userPKey,
        new PublicKey(ammKey),
        new PublicKey(tokenAddress),
        new BN(buyAmount * LAMPORTS_PER_SOL), // note that buyamount can be decimals
        // new BN(tokenAmountReceived).mul(
        //   new BN(LAMPORTS_PER_SOL * (1 - SLIPPAGE))
        // )
        new BN(tokenAmountReceived).mul(
          new BN(LAMPORTS_PER_SOL * (1 - SLIPPAGE))
        )
        // new BN(0)
      );

      tx.add(tokenBuyInstruction);

      const chainMemeInstruction = await Chain(
        userPKey,
        new PublicKey(ammKey),
        new PublicKey(tokenAddress),
        doc_id
      );

      tx.add(chainMemeInstruction);

      // add required metadata
      tx.recentBlockhash = latestBlockhash.blockhash;
      tx.feePayer = feePayer;
      // sign with user wallet
      const signedTx = await signTransaction(tx);

      // send tx
      return c.sendRawTransaction(signedTx.serialize());
    };
    if (!publicKey) {
      handleCloseModal();
      alert("Please connect your wallet first!");
    } else if (!isBuyAndChain) {
      try {
        // chain only
        if (docId) {
          const res = await sendChainTx(
            publicKey,
            id,
            bondingCurveAddress,
            docId
          );
          console.log("Chain tx res: ", res);
          setChainLoading(true);
          handleChainResult(docId);
        } else {
          setChainError(
            "Failed to chain this video. Please try again or use a different video."
          );
          await new Promise((resolve) => setTimeout(resolve, 2000));
          handleCloseModal();
        }
      } catch (error) {
        console.log("Chain error:", error);
      }
    } else if (buyAmount === "" || Number(buyAmount) === 0) {
      handleCloseModal();
      alert("Please enter an amount that you want to buy!");
    } else {
      try {
        let res;
        if (docId) {
          // TODO: fix me
          res = await sendBuyAndChainTx(
            publicKey,
            id,
            bondingCurveAddress,
            docId
          );
          console.log("Buy and chain tx res: ", res);
          setChainLoading(true);
          handleChainResult(docId);
        } else {
          res = await sendBuyTx(publicKey, id, bondingCurveAddress);
          console.log("Buy tx res: ", res);
          handleCloseModal();
        }
        if (isMobile) {
          setActiveTab(tabs[3].key);
        } else {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          updateSolBalance();
        }
      } catch (error) {
        console.log("chain error:", error);
      }
    }
  };

  const [sellAmount, setSellAmount] = useState(0);
  const handleSellAmount = (amount) => {
    if (amount > 0) {
      const parsedAmount = parseFloat(amount.toFixed(0));
      setSellAmount(parsedAmount);
    } else {
      setSellAmount(0);
    }
  };

  const [solAmountReceived, setSolAmountReceived] = useState(0);
  useEffect(() => {
    if (transactions && transactions.length > 0) {
      if (sellAmount > 0) {
        const lastTransaction = transactions[0];
        const outAmount = solAmountReceivedFromPool(
          lastTransaction.tokenInPool,
          lastTransaction.solInPool,
          sellAmount,
          LAMPORTS_PER_SOL
        );
        setSolAmountReceived(outAmount);
      } else {
        setSolAmountReceived(0);
      }
    }
  }, [sellAmount, transactions]);

  const onClickSell = async () => {
    const sendSellTx = async (userPKey, tokenAddress, ammKey) => {
      // manually specify connection until we can troubleshoot wallet provider
      const c = new Connection("https://api.devnet.solana.com");
      // latest blockhash and fee payer are required for partially signed tx
      const latestBlockhash = await c.getLatestBlockhash();
      const feePayer = userPKey;
      // tx wrapper for instructions
      const tx = new Transaction();

      console.log("solAmountReceived: ", solAmountReceived);

      // create token buy tx
      const tokenSellInstruction = await Sell(
        userPKey,
        new PublicKey(ammKey),
        new PublicKey(tokenAddress),
        new BN(sellAmount).mul(new BN(LAMPORTS_PER_SOL)),
        new BN(solAmountReceived * LAMPORTS_PER_SOL * (1 - SLIPPAGE))
      );

      tx.add(tokenSellInstruction);

      // add required metadata
      tx.recentBlockhash = latestBlockhash.blockhash;
      tx.feePayer = feePayer;

      // sign with user wallet
      const signedTx = await signTransaction(tx);

      // send tx
      return c.sendRawTransaction(signedTx.serialize());
    };

    if (!publicKey) {
      alert("Please connect your wallet first!");
    } else if (sellAmount === "" || Number(sellAmount) === 0) {
      alert("Please enter an amount that you want to sell!");
    } else {
      try {
        const res = await sendSellTx(publicKey, id, bondingCurveAddress);
        console.log("Res: ", res);
        if (isMobile) {
          setActiveTab(tabs[3].key);
        } else {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          updateSolBalance();
        }
      } catch (error) {
        console.log("Sell error:", error);
      }
    }
  };

  const handleBuyInput = (event) => {
    let inputValue = event.target.value;
    if (inputValue === "") {
      setBuyAmount(inputValue);
    } else if (/^[0-9\b]+(\.[0-9\b]*)?$/.test(inputValue)) {
      const decimalIndex = inputValue.indexOf(".");
      if (decimalIndex !== -1) {
        const decimals = inputValue.slice(decimalIndex + 1);
        if (decimals.length > 3) {
          inputValue = parseFloat(inputValue).toFixed(3);
        }
      }
      setBuyAmount(inputValue);
    }
  };

  const handleSellInput = (event) => {
    let inputValue = event.target.value;
    if (inputValue === "" || /^[0-9\b]*$/.test(inputValue)) {
      setSellAmount(inputValue);
    }
  };

  const [isChain, setIsChain] = useState(true);
  const [buyVolumeNeeded, setBuyVolumeNeeded] = useState(0);
  const [availableChains, setAvailableChains] = useState(0);
  const [isBuyAndChain, setIsBuyAndChain] = useState(true);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      const lastTransaction = transactions[0];
      const cumulativeVolumeNum =
        parseInt(lastTransaction.cumulativeVolume, 16) / LAMPORTS_PER_SOL;
      const cumulativeVolumeThresholdNum =
        parseInt(lastTransaction.cumulativeVolumeThreshold, 16) /
        LAMPORTS_PER_SOL;
      setBuyVolumeNeeded(cumulativeVolumeThresholdNum - cumulativeVolumeNum);
      setAvailableChains(lastTransaction.availableChains);
    }
  }, [transactions, buyAmount]);

  useEffect(() => {
    if (isChain && availableChains === 0) {
      setBuyAmount(buyVolumeNeeded);
    }
  }, [isChain, availableChains, buyVolumeNeeded]);

  const onClickChain = async () => {
    if (!publicKey) {
      alert("Please connect your wallet first!");
    } else {
      setChainingEnabled(true);
      setIsBuyAndChain(false);
    }
  };

  if (!isActive) {
    return null;
  }

  return (
    <div
      className='rounded-lg shadow-md p-4 my-4'
      style={{ backgroundColor: "#1f1e24" }}>
      <BuySellProfileComponent
        profile={profile}
        isMobile={isMobile}
        chainedMemeIndex={displayMemeIndex}
        updateDisplayMemeIndex={updateDisplayMemeIndex}
      />

      <div className='mt-2 flex justify-between'>
        <button
          onClick={() => {
            setIsBuy(true);
            setBuyAmount(0);
            setIsChain(false);
          }}
          className={`px-4 py-2 rounded ${
            isBuy && !isChain
              ? "bg-yellow-300 hover:bg-yellow-500 text-black font-semibold focus:outline-none"
              : "bg-gray-600 px-4 py-2 rounded hover:bg-gray-500 font-semibold focus:outline-none"
          }`}>
          Buy
        </button>
        <button
          onClick={() => {
            setIsChain(true);
            setIsBuy(false);
            availableChains === 0 && setBuyAmount(buyVolumeNeeded);
          }}
          className={`px-4 py-2 rounded ${
            isChain
              ? "background-chain-gradient text-black font-semibold focus:outline-none"
              : "bg-gray-600 px-4 py-2 rounded hover:bg-gray-500 font-semibold focus:outline-none"
          }`}>
          Chain
        </button>
        <button
          onClick={() => {
            setIsBuy(false);
            setIsChain(false);
          }}
          className={`px-4 py-2 rounded ${
            !isBuy && !isChain
              ? "bg-purple-400 hover:bg-purple-600 text-black font-semibold focus:outline-none"
              : "bg-gray-600 px-4 py-2 rounded hover:bg-gray-500 font-semibold focus:outline-none"
          }`}>
          Sell
        </button>
      </div>
      {isChain ? (
        availableChains === 0 ? (
          <div className='my-4'>
            <div className='flex justify-between mb-4'>
              <p>
                A chain is a collection of TikTok videos that users link
                together to represent a viral song, trend or meme. The longer
                the chain, the more attention a token will receive. There's
                currently no quota to chain a new video. To chain a new video,
                there needs to be another {buyVolumeNeeded.toFixed(2)} sol in
                buy volume.
              </p>
            </div>
            <button
              onClick={() => {
                onClickBuy();
              }}
              className={`w-full background-chain-gradient text-black px-4 py-2 rounded font-semibold focus:outline-none ${
                isChain ? "animate-button" : ""
              }`}>
              Buy {buyVolumeNeeded.toFixed(2)} Sol & Chain
            </button>
          </div>
        ) : (
          <div className='my-4'>
            <div className='flex justify-between mb-4'>
              <p>
                There's {availableChains} available video(s) you can chain now.
                Find a TikTok video related to the current one(s), perhaps
                created by the same author or similar in songs and trends. The
                longer the chain, the more attention a token will receive. No
                capital required and start chaining below!
              </p>
            </div>
            <button
              onClick={() => {
                onClickChain();
              }}
              className={`w-full background-chain-gradient text-black px-4 py-2 rounded font-semibold focus:outline-none ${
                isChain ? "animate-button" : ""
              }`}>
              Chain Video
            </button>
          </div>
        )
      ) : (
        <div className='my-4'>
          <div className='flex items-center justify-center my-4'>
            <button
              className='text-xl'
              onClick={() =>
                isBuy
                  ? handleBuyAmount(Number(buyAmount) - BUY_INCREMENT)
                  : handleSellAmount(Number(sellAmount) - SELL_INCREMENT)
              }>
              -
            </button>
            <OutlinedInput
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "gray",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: isBuy ? "#fde047" : "#c084fc",
                },
              }}
              endAdornment={
                <InputAdornment
                  position='end'
                  disableTypography
                  sx={{ color: "white" }}>
                  {isBuy ? "SOL" : profile.ticker}
                </InputAdornment>
              }
              className='mx-4'
              inputProps={{ style: { color: "white" } }}
              value={isBuy ? buyAmount : sellAmount}
              onChange={(e) => (isBuy ? handleBuyInput(e) : handleSellInput(e))}
            />
            <button
              className='text-xl'
              onClick={() =>
                isBuy
                  ? handleBuyAmount(Number(buyAmount) + BUY_INCREMENT)
                  : handleSellAmount(Number(sellAmount) + SELL_INCREMENT)
              }>
              +
            </button>
          </div>
          {isBuy ? (
            <div className='flex items-center my-4'>
              <button
                className='text-xs bg-yellow-300 hover:bg-yellow-500 px-2 py-1 rounded focus:outline-none text-black'
                onClick={() => handleBuyAmount(0)}>
                {" "}
                reset
              </button>
              <button
                className='ml-2 text-xs bg-yellow-300 hover:bg-yellow-500 px-2 py-1 rounded focus:outline-none text-black'
                onClick={() => handleBuyAmount(0.5)}>
                {" "}
                0.5 SOL
              </button>
              <button
                className='ml-2 text-xs bg-yellow-300 hover:bg-yellow-500 px-2 py-1 rounded focus:outline-none text-black'
                onClick={() => handleBuyAmount(1)}>
                {" "}
                1 SOL
              </button>
              <button
                className='ml-2 text-xs bg-yellow-300 hover:bg-yellow-500 px-2 py-1 rounded focus:outline-none text-black'
                onClick={() => handleBuyAmount(2)}>
                {" "}
                2 SOL
              </button>
              <button
                className='ml-2 text-xs bg-yellow-300 hover:bg-yellow-500 px-2 py-1 rounded focus:outline-none text-black'
                onClick={() => handleBuyAmount(5)}>
                {" "}
                5 SOL
              </button>
            </div>
          ) : (
            <div className='flex items-center my-4'>
              <button
                className='text-xs bg-purple-400 hover:bg-purple-600 px-2 py-1 rounded focus:outline-none text-black'
                onClick={() => handleSellAmount(0)}>
                {" "}
                reset
              </button>
              <button
                className='ml-2 text-xs bg-purple-400 hover:bg-purple-600 px-2 py-1 rounded focus:outline-none text-black'
                onClick={() =>
                  handleSellAmount(Math.floor(Number(holdings) * 0.25))
                }>
                {" "}
                25%
              </button>
              <button
                className='ml-2 text-xs bg-purple-400 hover:bg-purple-600 px-2 py-1 rounded focus:outline-none text-black'
                onClick={() =>
                  handleSellAmount(Math.floor(Number(holdings) * 0.5))
                }>
                {" "}
                50%
              </button>
              <button
                className='ml-2 text-xs bg-purple-400 hover:bg-purple-600 px-2 py-1 rounded focus:outline-none text-black'
                onClick={() =>
                  handleSellAmount(Math.floor(Number(holdings) * 0.75))
                }>
                {" "}
                75%
              </button>
              <button
                className='ml-2 text-xs bg-purple-400 hover:bg-purple-600 px-2 py-1 rounded focus:outline-none text-black'
                onClick={() => handleSellAmount(Math.floor(Number(holdings)))}>
                {" "}
                100%
              </button>
            </div>
          )}
          {/* Price display */}
          <div className='flex justify-between'>
            <p
              className={`text-sm text-left mb-4 ${
                (isBuy && buyAmount > solBalance) ||
                (!isBuy && sellAmount > Number(holdings))
                  ? "text-red-600"
                  : "text-gray-400"
              }`}>
              {isBuy
                ? `Balance: ${solBalance.toFixed(2)} SOL`
                : `Holding: ${Math.floor(Number(holdings))}`}
            </p>
            <p className='text-sm text-right text-gray-400 mb-4'>
              {isBuy
                ? "Receive: " + tokenAmountReceived + " " + ticker
                : "Receive: " + solAmountReceived + " SOL"}
            </p>
          </div>

          {isBuy &&
            availableChains === 0 &&
            (buyAmount < buyVolumeNeeded ? (
              <div className='flex justify-between mb-4'>
                <p>
                  Buy at least {buyVolumeNeeded.toFixed(2)} sol to chain a new
                  TikTok.
                </p>
              </div>
            ) : (
              <div className='flex justify-between mb-4'>
                <p>Click buy below to buy & (optional) chain! </p>
              </div>
            ))}
          {isBuy ? (
            <button
              onClick={onClickBuy}
              className='w-full bg-yellow-300 text-black px-4 py-2 rounded hover:bg-yellow-500 font-semibold focus:outline-none'>
              Buy
            </button>
          ) : (
            <button
              onClick={onClickSell}
              className='w-full bg-purple-400 text-black px-4 py-2 rounded hover:bg-purple-600 font-semibold focus:outline-none'>
              Sell
            </button>
          )}
        </div>
      )}
      {!isMobile && (
        <TokenInfoComponent
          profile={profile}
          transactions={transactions}
          isActive={true}
        />
      )}
      <ChainMemeComponent
        key={key}
        isOpen={chainingEnabled}
        onClose={handleCloseModal}
        onBuyAndChain={onBuyAndChainMeme}
        autoFillTitle={title}
        isBuyAndChain={isBuyAndChain}
        chainError={chainError}
        chainLoading={chainLoading}
      />
    </div>
  );
};

export default BuySellComponent;
