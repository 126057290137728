import React from "react";
import { useNavigate } from "react-router-dom";
import { eventType } from "../util/util";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

const NotificationComponent = ({ ticker, tx, isVisible }) => {
  const navigate = useNavigate();

  const getTypeColor = (type) => {
    if (type === eventType.MINT) {
      return "bg-blue-200";
    } else if (type === eventType.BUY) {
      return "bg-yellow-400";
    } else if (type === eventType.SELL) {
      return "bg-purple-400";
    } else if (type === eventType.CHAIN) {
      return "bg-chain-gradient";
    } else if (type === eventType.BURN) {
      return "bg-red-600";
    }
  };

  const truncateAddress = (address) => {
    if (address) {
      return address.slice(0, 6);
    } else {
      return "";
    }
  };

  const getMessage = (tx) => {
    const type = tx.type;
    const user = truncateAddress(tx.trader);
    if (type === eventType.MINT) {
      return `${user} created\u00A0`;
    } else if (type === eventType.BUY) {
      return `${user} bought ${(
        parseInt(tx.inputAmount, 16) / LAMPORTS_PER_SOL
      ).toFixed(2)}  SOL of\u00A0`;
    } else if (type === eventType.SELL) {
      return `${user} sold ${(
        parseInt(tx.outputAmount, 16) / LAMPORTS_PER_SOL
      ).toFixed(2)}  SOL of\u00A0`;
    } else if (type === eventType.CHAIN) {
      return `${user} chained a new clip to\u00A0`;
    } else if (type === eventType.BURN) {
      return "Burned\u00A0";
    }
  };

  return (
    <div
      className={`notification flex flex-row ${
        isVisible ? "visible" : ""
      } ${getTypeColor(tx.type)}`}>
      <p>{getMessage(tx)}</p>
      <div
        className='hover:underline cursor-pointer'
        onClick={() => navigate(`/${tx.tokenMintAddr}`)}>
        {ticker ? ticker : truncateAddress(tx.tokenMintAddr)}
      </div>
    </div>
  );
};

export default NotificationComponent;
