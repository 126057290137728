import { LAMPORTS_PER_SOL } from "@solana/web3.js";

const timeSince = (unixTimestamp) => {
  const pastDate = new Date(unixTimestamp * 1000); // Convert to milliseconds
  const now = new Date();
  const timeDiff = now - pastDate;

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (minutes < 60) {
    return `${minutes}m`;
  } else if (hours < 24) {
    return `${hours}h`;
  } else if (days < 7) {
    return `${days} day${days > 1 ? "s" : ""}`;
  } else if (weeks < 4) {
    return `${weeks} week${weeks > 1 ? "s" : ""}`;
  } else if (months < 12) {
    return `${months} month${months > 1 ? "s" : ""}`;
  } else {
    return `${years} year${years > 1 ? "s" : ""}`;
  }
};

function formatTimestamp(timestamp) {
  const date = new Date(timestamp._seconds * 1000);

  // Formatting each part of the date-time to ensure leading zeros
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  // Combine the date and time parts in the desired format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function timeAgo(dateString, isMobile) {
  const givenTime = new Date(dateString);
  const currentTime = new Date();
  const timeDifference = currentTime - givenTime;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30); // Approximation
  const years = Math.floor(days / 365); // Approximation

  if (isMobile) {
    if (seconds < 60) {
      return `${seconds}s`;
    } else if (minutes < 60) {
      return `${minutes}min`;
    } else if (hours < 24) {
      return `${hours}h`;
    } else if (days < 7) {
      return `${days}d`;
    } else if (weeks < 4) {
      return `${weeks}w`;
    } else if (months < 12) {
      return `${months}mon`;
    } else {
      return `${years}y`;
    }
  } else {
    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else if (days < 7) {
      return `${days} days ago`;
    } else if (weeks < 4) {
      return `${weeks} weeks ago`;
    } else if (months < 12) {
      return `${months} months ago`;
    } else {
      return `${years} years ago`;
    }
  }
}

function transformFirstUpperCase(name) {
  if (name === eventType.BUY_AND_CHAIN) {
    return "Buy & Chain";
  } else if (name) {
    return name.charAt(0).toUpperCase() + name.substring(1);
  } else {
    return "";
  }
}

function truncateAddress(address, numLetters) {
  if (address) {
    return `${address.slice(0, numLetters)}...${address.slice(-numLetters)}`;
  } else {
    return "";
  }
}

const eventType = {
  MINT: "mint",
  BUY: "buy",
  SELL: "sell",
  CHAIN: "chain",
  BURN: "burn",
  CHAIN_AND_BURN: "Chain & Burn",
};

const FEE = 100;

const tokenAmountReceivedFromPool = (
  tokenInPool,
  solInPool,
  inAmount,
  LAMPORTS_PER_SOL
) => {
  tokenInPool = parseInt(tokenInPool, 16);
  solInPool = parseInt(solInPool, 16);

  const outAmount =
    (tokenInPool -
      (tokenInPool * solInPool) /
        (solInPool + inAmount * LAMPORTS_PER_SOL * ((10000 - FEE) / 10000))) /
    LAMPORTS_PER_SOL;

  return outAmount.toFixed(0);
};

const solAmountReceivedFromPool = (
  tokenInPool,
  solInPool,
  inAmount,
  LAMPORTS_PER_SOL
) => {
  tokenInPool = parseInt(tokenInPool, 16);
  solInPool = parseInt(solInPool, 16);

  let outAmount =
    ((solInPool -
      (tokenInPool * solInPool) / (tokenInPool + inAmount * LAMPORTS_PER_SOL)) *
      ((10000 - FEE) / 10000)) /
    LAMPORTS_PER_SOL;
  if (solInPool / LAMPORTS_PER_SOL - outAmount < VIRTUAL_SOL) {
    outAmount = solInPool / LAMPORTS_PER_SOL - VIRTUAL_SOL;
  }
  console.log("real outamount: ", outAmount);

  return outAmount.toFixed(2).replace(/\.?0+$/, "");
};

const totalSupply = 10000000000;
const SOL_PRICE = 150;
const VIRTUAL_SOL = 20;
const MIGRATION_FACTOR = 4;
const MIGRATION_SOL = VIRTUAL_SOL * MIGRATION_FACTOR;
const MIGRATION_CHAIN_COUNT = 8;

const marketcapFromPool = (solInPool, tokenInPool) => {
  const priceInSol =
    ((parseInt(solInPool, 16) * parseInt(tokenInPool, 16)) /
      (parseInt(tokenInPool, 16) - LAMPORTS_PER_SOL) -
      parseInt(solInPool, 16)) /
    LAMPORTS_PER_SOL;
  const marketcap = priceInSol * totalSupply * SOL_PRICE;

  return marketcap.toFixed(3).replace(/\.?0+$/, "");
};

const formatTokenInPoolAmount = (tokenInPool) => {
  let parsedTokenInPool;
  if (tokenInPool) {
    parsedTokenInPool = parseInt(tokenInPool, 16) / LAMPORTS_PER_SOL;
  } else {
    parsedTokenInPool = totalSupply;
  }
  return parsedTokenInPool.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const formatSolInPoolAmount = (solInPool) => {
  let parsedSolInPool;
  if (solInPool) {
    parsedSolInPool = parseInt(solInPool, 16) / LAMPORTS_PER_SOL;
  } else {
    parsedSolInPool = VIRTUAL_SOL;
  }
  return parsedSolInPool.toFixed(2).replace(/\.?0+$/, "");
};

const getUpdatedTokenHoldings = (currentHolding, lastTransaction) => {
  const type = lastTransaction.type;
  if (type === "sell") {
    const tokenSellAmount =
      parseInt(lastTransaction.inputAmount, 16) / LAMPORTS_PER_SOL;
    return Number(currentHolding) - tokenSellAmount >= 0
      ? Number(currentHolding) - tokenSellAmount
      : 0;
  } else if (type === "buy") {
    const tokenBuyAmount =
      parseInt(lastTransaction.outputAmount, 16) / LAMPORTS_PER_SOL;
    return Number(currentHolding) + tokenBuyAmount;
  } else {
    return Number(currentHolding);
  }
};

// const SOCKET_SERVER_URL = "ws://localhost:3001";
const SOCKET_SERVER_URL = process.env.REACT_APP_RAILWAY_WSS;

const formatNumber = (num) => {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(2).replace(/\.0$/, "") + "b";
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(2).replace(/\.0$/, "") + "m";
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(2).replace(/\.0$/, "") + "k";
  }
  return num;
};

export {
  timeSince,
  formatTimestamp,
  transformFirstUpperCase,
  truncateAddress,
  eventType,
  timeAgo,
  tokenAmountReceivedFromPool,
  solAmountReceivedFromPool,
  marketcapFromPool,
  MIGRATION_SOL,
  formatTokenInPoolAmount,
  formatSolInPoolAmount,
  SOCKET_SERVER_URL,
  getUpdatedTokenHoldings,
  formatNumber,
  VIRTUAL_SOL,
  MIGRATION_CHAIN_COUNT,
  SOL_PRICE,
};
