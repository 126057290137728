import React, { useState, useEffect } from "react";
import {
  addMeme,
  getMemeByVideo,
  publishOffchainMetadata,
} from "../backendApi/api";
import { CreateAmm } from "../contracts/amm.ts";
import { CreateToken } from "../contracts/token.ts";
import {
  OffchainMetadataFromToken,
  OnchainMetadataFromToken,
} from "../contracts/tokenMetadata.ts";
import { CreatePool } from "../contracts/pool.ts";
import { DepositLiquidity } from "../contracts/deposit.ts";
import { Keypair, Transaction, Connection } from "@solana/web3.js";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "../components/Tabs";
import { useWallet } from "@solana/wallet-adapter-react";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import TikTokVideoComponent from "../components/TikTokVideoComponent";
import { SOCKET_SERVER_URL } from "../util/util";
import "../components/ChainGradient.css";

const TICKER_NAME_MAX_LENGTH = 10;
const TICKER_TITLE_MAX_LENGTH = 20;
const IPFS_GATEWAY = "https://ipfs.io/ipfs/";

const CreatePage = ({ activeTab, setActiveTab, tabs }) => {
  const navigate = useNavigate();

  const tabName = "create";

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setActiveTab(tabName);
  }, [setActiveTab]);

  const [tickerName, setTickerName] = useState("");
  const [tickerTitle, setTickerTitle] = useState("");
  const [tickerImgUrl, setTickerImgUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isShowCreate, setIsShowCreate] = useState(true);
  const [memeId, setMemeId] = useState("");
  const [chainedMemeIndex, setChainMemeIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [createButtonText, setCreateButtonText] = useState("Create");

  const [searchTerm, setSearchTerm] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [message, setMessage] = useState("");

  // get the user wallet info
  const { publicKey, signTransaction } = useWallet();
  const userPKey = publicKey;

  const [hasCheckedVideoUrl, setHasCheckedVideoUrl] = useState(false);
  const [videoPlayUrl, setVideoPlayUrl] = useState("");

  const handleSearchVideoUrl = async () => {
    setSearchLoading(true);
    if (searchTerm === "") {
      setErrorMessage("No TikTok URL given.");
    } else {
      const meme = { videoUrl: searchTerm };
      const res = await getMemeByVideo(meme);

      if (res.status === 200) {
        setMessage("Congrats, you are first! Launch it now...");
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setTickerImgUrl(searchTerm);
        setHasCheckedVideoUrl(true);
        setVideoPlayUrl(res.data.videoPlayUrl);
      } else if (res.response.status === 400) {
        const { id, chainedMemeIndex } = res.response.data;
        if (id) {
          setMessage("Navigating to this cool TikTok meme...");
          await new Promise((resolve) => setTimeout(resolve, 1000));
          navigate(`/${id}`, {
            state: { chainedMemeIndex: chainedMemeIndex },
          });
        } else {
          setErrorMessage(res.response.data.error);
        }
      } else {
        setErrorMessage(res.response.data.error);
      }
    }
    setSearchLoading(false);
  };

  const handleSearchTermChange = (event) => {
    setErrorMessage("");
    setSearchTerm(event.target.value);
  };

  const handleTickerNameChange = (event) => {
    setTickerName(event.target.value.toUpperCase());
  };

  useEffect(() => {
    if (tickerName.length > TICKER_NAME_MAX_LENGTH) {
      setErrorMessage(
        `Ticker name cannot be longer than ${TICKER_NAME_MAX_LENGTH} characters.`
      );
    } else {
      setErrorMessage("");
    }
  }, [tickerName]);

  useEffect(() => {
    if (tickerTitle.length > TICKER_TITLE_MAX_LENGTH) {
      setErrorMessage(
        `Name cannot be longer than ${TICKER_TITLE_MAX_LENGTH} characters.`
      );
    } else {
      setErrorMessage("");
    }
  }, [tickerTitle]);

  const handleTickerTitleChange = (event) => {
    setTickerTitle(event.target.value);
  };

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false);
        // setMessage(
        //   "Go to Trade tab and click on latest uploads to find your meme!"
        // );
        setCreateButtonText("Launch now!");
      }, 5000);

      // Clean up the timer if the component unmounts or if loading changes
      return () => clearTimeout(timer);
    }
  }, [loading]);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault(); // Prevent the default form submission behavior

    const meme = {
      ticker: tickerName,
      title: tickerTitle,
      videoUrl: tickerImgUrl,
      description: "",
      isOriginalMeme: true,
    };

    // check if user has wallet connected
    if (!userPKey) {
      setLoading(false);
      setErrorMessage("Please connect your wallet to create a meme.");
      return;
    }

    // try add meme to db
    const res = await addMeme(meme);
    setLoading(false);
    setErrorMessage("");

    if (res.status === 200) {
      const data = res.data;

      const {
        ticker,
        title,
        videoUrl,
        videoPlayUrl,
        thumbnailUrl,
        description,
      } = data;
      const offchainMetadata = OffchainMetadataFromToken(
        title,
        ticker,
        description,
        thumbnailUrl,
        videoUrl,
        videoPlayUrl
      );

      // publish offchain metadata to IPFS
      const offchainMetadataRes = await publishOffchainMetadata(
        offchainMetadata
      );
      const { IpfsHash } = offchainMetadataRes;

      // const IpfsHash = "QmYjbc5ZHVPz8YQD5xV7kEcVyEWjxTB8dvBGyZubAngsrk";
      const onchainMetadata = OnchainMetadataFromToken(
        title,
        ticker,
        IPFS_GATEWAY + IpfsHash
      );
      // the uri should point to our own hosted token metadata
      // TODO: implement that

      // build the meme token transaction
      const txRes = await sendTxs(userPKey, onchainMetadata, res.data.id);
      console.log("create meme token tx sent, got response: ", txRes);

      setLoading(true);

      const ws = new WebSocket(SOCKET_SERVER_URL);

      ws.addEventListener("open", () => {
        console.log("Connected to WebSocket server");

        // Register the client with the WebSocket server
        ws.send(JSON.stringify({ type: "register", token: res.data.id }));
      });

      ws.addEventListener("message", async (message) => {
        try {
          setLoading(false);

          const res = JSON.parse(message.data);

          ws.close();

          if (res.status === 200) {
            setCreateButtonText("Launching Now...");
            await new Promise((resolve) => setTimeout(resolve, 1000));
            navigate(`/${res.id}`);
          } else if (res.status === 409) {
            setErrorMessage(res.error);
            setIsShowCreate(false);
            setMemeId(res.id);
            setChainMemeIndex(res.chainedMemeIndex);
          } else {
            setErrorMessage(res.error);
          }
        } catch (error) {
          console.error("Error parsing message:", error);
        }
      });

      ws.addEventListener("close", () => {
        console.log("Disconnected from WebSocket server");
      });

      ws.addEventListener("error", (error) => {
        console.error("WebSocket error:", error);
      });
    } else if (res.response.status === 409) {
      const data = res.response.data.data;
      setErrorMessage(res.response.data.error);
      if (data.tokenMintAddress) {
        setIsShowCreate(false);
        setMemeId(data.tokenMintAddress);
        setChainMemeIndex(data.chainedMemeIndex);
      }
    } else if (res.response.status === 400) {
      setErrorMessage(res.response.data.error);
    } else {
      setErrorMessage(res.response.data.error);
    }
  };

  // sendTxs builds a multi-instruction transaction that includes:
  // token creation, token metadata, amm creation, pool creation, and liquidity deposit.
  const sendTxs = async (userPKey, metadata, id) => {
    // manually specify connection until we can troubleshoot wallet provider
    const c = new Connection("https://api.devnet.solana.com");
    // latest blockhash and fee payer are required for partially signed tx
    const latestBlockhash = await c.getLatestBlockhash();
    const feePayer = userPKey;
    // tx wrapper for instructions
    const tx = new Transaction();

    // keypair for token mint
    const mintAccount = Keypair.generate();

    // create token mint tx
    const tokenMintInstruction = await CreateToken(
      userPKey,
      mintAccount.publicKey,
      metadata
    );

    // keypair for amm
    const amm = Keypair.generate();

    // create amm tx
    const ammInstruction = await CreateAmm(userPKey, amm.publicKey);

    // create pool tx
    const poolInstruction = await CreatePool(
      userPKey,
      amm.publicKey,
      mintAccount.publicKey
    );

    // create deposit tx
    const depositInstruction = await DepositLiquidity(
      userPKey,
      amm.publicKey,
      mintAccount.publicKey,
      id
    );

    // create multicall tx
    // strictly ordered

    // mint token to user wallet
    tx.add(tokenMintInstruction);
    // create amm with mint account
    tx.add(ammInstruction);
    // create pool on the amm, with a pool account for the token and anther for sol
    tx.add(poolInstruction);
    // deposit from user wallet to pool account for token
    tx.add(depositInstruction);

    // add required metadata
    tx.recentBlockhash = latestBlockhash.blockhash;
    tx.feePayer = feePayer;

    // sign multicall tx
    // partial sign with mint account
    tx.partialSign(mintAccount);
    // sign with user wallet
    const signedTx = await signTransaction(tx);

    // send tx
    return c.sendRawTransaction(signedTx.serialize());
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    color: "#9ca3af",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    color: "black",
  };

  const buttonContainerStyle = {
    textAlign: "center",
  };

  const buttonStyle = {
    marginTop: "10px",
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    outline: "none",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    width: "200px",
    height: "42px",
  };

  const formGroupStyle = {
    marginTop: "10px",
    marginBottom: "10px",
  };

  const formContainerStyle = {
    padding: "10px",
    borderRadius: "5px",
    maxWidth: "600px",
    width: "100%",
  };

  const formTitleStyle = {
    textAlign: "center",
    fontSize: "24px",
    margin: "20px 0 20px 0",
  };

  const onClickCreateTabCallBack = (key) => {
    navigate(`/${key}`);
  };

  return (
    <>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onClickCallBack={onClickCreateTabCallBack}
      />
      <div className='container mx-auto px-4'>
        <div className='max-w-xl mx-auto' style={formContainerStyle}>
          {hasCheckedVideoUrl ? (
            <>
              {isMobile ? (
                <div className='flex items-center justify-center cursor-pointer'>
                  <TikTokVideoComponent videoPlayUrl={videoPlayUrl} />
                </div>
              ) : (
                <div className='flex flex-col items-center cursor-pointer w-full'>
                  <TikTokVideoComponent videoPlayUrl={videoPlayUrl} />
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div style={formGroupStyle}>
                  <div style={formGroupStyle}>
                    <label style={labelStyle}>Name:</label>
                    <input
                      type='text'
                      name='tickerTitle'
                      style={inputStyle}
                      value={tickerTitle}
                      onChange={handleTickerTitleChange}
                    />
                  </div>
                  <label style={labelStyle}>Ticker:</label>
                  <input
                    type='text'
                    name='tickerName'
                    style={inputStyle}
                    value={tickerName}
                    onChange={handleTickerNameChange}
                  />
                </div>
                {errorMessage && (
                  <div style={{ color: "red", marginBottom: "10px" }}>
                    {errorMessage}
                  </div>
                )}
                <div style={formGroupStyle}>
                  <div
                    className='flex items-center justify-center'
                    style={buttonContainerStyle}>
                    {isShowCreate ? (
                      <>
                        <button
                          type='submit'
                          className='background-chain-gradient font-bold text-black flex items-center justify-center'
                          style={buttonStyle}
                          disabled={
                            tickerName === "" ||
                            tickerName.length > TICKER_NAME_MAX_LENGTH ||
                            tickerImgUrl === "" ||
                            tickerTitle.length > TICKER_TITLE_MAX_LENGTH
                          }>
                          {loading ? (
                            <CircularProgress
                              size={20}
                              style={{ color: "black" }}
                            />
                          ) : (
                            createButtonText
                          )}
                        </button>
                        {/* {message && (
                          <p className='text-sm text-yellow-300'> {message} </p>
                        )} */}
                      </>
                    ) : (
                      <button
                        onClick={() =>
                          navigate(`/${memeId}`, {
                            state: { chainedMemeIndex: chainedMemeIndex },
                          })
                        }
                        className='bg-yellow-300 md:hover:bg-yellow-500 font-bold text-black'
                        style={buttonStyle}>
                        Visit Meme
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </>
          ) : (
            <>
              {" "}
              <h2 style={formTitleStyle}>Launch Clip</h2>
              {/* <ol style={{ color: "#9ca3af" }}>
                <li>
                  All clips are fair launch. Zero capital required. Every token
                  must be bought from the pool. See{" "}
                  <a
                    href='https://x.com/clipdotmeme'
                    className='text-white underline hover:font-bold'
                    target='_blank'
                    rel='noopener noreferrer'>
                    twitter
                  </a>{" "}
                  for more info.
                </li>
                <li>
                  To launch a clip, find any TikTok, click{" "}
                  <i>share, copy link</i>, and enter the video url below.
                </li>
                <li>
                  Each TikTok can be used only
                  <strong style={{ color: "white" }}> once</strong>. A TikTok
                  can be either{" "}
                  <strong style={{ color: "white" }}> Minted</strong> as an
                  original clip, or
                  <strong style={{ color: "white" }}> Chained</strong> to an
                  existing clip.
                </li>
                <li>
                  A "Clip Chain" is a collection of TikToks that users link
                  together to represent a viral song, trend or meme. TikToks in
                  the same chain represent the same original clip token.
                </li>
                <li>
                  Every time cumulative buy volume for a token exceeds a
                  threshold, all users become eligible to chain a new video to
                  the token. Longer the chain, more attention the token will
                  receive.
                </li>
              </ol> */}
              <p style={{ color: "#9ca3af" }}>
                All clips are fair launch. Every token (1 bil total) must be
                bought from the pool. No seed capital required. Follow us on{" "}
                <a
                  href='https://x.com/clipdotmeme'
                  className='text-white underline hover:font-bold'
                  target='_blank'
                  rel='noopener noreferrer'>
                  twitter
                </a>{" "}
                for more updates.
              </p>
              <p style={{ color: "#9ca3af", marginTop: 8 }}>
                To launch a clip, find any TikTok, click <i>share, copy link</i>
                , and paste the video url below. Each TikTok can be used only
                <strong style={{ color: "white" }}> once</strong>. You will be
                directed to the clip if the TikTok has already been used.
              </p>
              <div style={formGroupStyle}>
                <div className='mb-4 flex items-center '>
                  <input
                    type='text'
                    name='searchTerm'
                    style={inputStyle}
                    value={searchTerm}
                    placeholder='TikTok Video URL'
                    onChange={handleSearchTermChange}
                  />
                  <Button
                    variant='contained'
                    startIcon={
                      !isMobile &&
                      !searchLoading && (
                        <SearchIcon style={{ color: "black" }} />
                      )
                    }
                    className='background-chain-gradient'
                    sx={{
                      marginLeft: "4px",
                      padding: "8px",
                      height: "42px",
                      width: isMobile ? "" : "160px",
                      cursor: "pointer",
                      color: "black",
                    }}
                    onClick={handleSearchVideoUrl}>
                    {searchLoading ? (
                      <CircularProgress size={20} style={{ color: "black" }} />
                    ) : isMobile ? (
                      <SearchIcon style={{ color: "black" }} />
                    ) : (
                      "search"
                    )}
                  </Button>
                </div>
                {errorMessage && (
                  <div style={{ color: "red", marginBottom: "10px" }}>
                    {errorMessage}
                  </div>
                )}
                {message && (
                  <p className='text-sm text-yellow-300'> {message} </p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CreatePage;
