import {
  program,
  getAmmKey,
  getPoolKey,
  getPoolAuthority,
  PoolData,
} from "./util/anchor.ts";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";
import { getAssociatedTokenAddress } from "@solana/spl-token";

export const CreatePool = async (
  creator: PublicKey,
  ammId: PublicKey,
  mint: PublicKey
): Promise<TransactionInstruction> => {
  const ammKey = await getAmmKey(ammId);
  const poolAuthority = await getPoolAuthority(ammKey, mint);
  const poolAccount = await getPoolKey(ammKey, mint);
  const poolAccountA = await getAssociatedTokenAddress(
    mint,
    poolAuthority,
    true
  );
  return program.methods
    .createPool()
    .accounts({
      payer: creator,
      amm: ammKey,
      pool: poolAccount,
      poolAuthority: poolAuthority,
      mintA: mint,
      poolAccountA: poolAccountA,
    })
    .instruction();
};

export const GetPoolData = async (poolId: PublicKey): Promise<PoolData> => {
  return program.account.pool.fetch(poolId);
};
