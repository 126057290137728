import React, { useEffect, useState } from "react";
import TikTokVideoComponent from "./TikTokVideoComponent";
import { IconButton } from "@mui/material";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
import SkipPreviousRoundedIcon from "@mui/icons-material/SkipPreviousRounded";

const BuySellProfileComponent = ({
  isMobile,
  profile,
  chainedMemeIndex,
  updateDisplayMemeIndex,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [videoPlayUrl, setVideoPlayUrl] = useState("");
  const [chainedMemeData, setChainedMemeData] = useState([]);
  const [chainedMemeDataLength, setChainedMemeDataLength] = useState(0);

  useEffect(() => {
    setChainedMemeData(profile.chainedMemeData);
  }, [profile]);

  const [displayMemeIndex, setDisplayMemeIndex] = useState(0);

  useEffect(() => {
    if (chainedMemeIndex) {
      setDisplayMemeIndex(chainedMemeIndex);
    }
  }, [chainedMemeIndex]);

  useEffect(() => {
    if (chainedMemeData && chainedMemeData.length > displayMemeIndex) {
      setTitle(chainedMemeData[displayMemeIndex].title);
      setVideoPlayUrl(chainedMemeData[displayMemeIndex].videoPlayUrl);
      setDescription(chainedMemeData[displayMemeIndex].description);
      setChainedMemeDataLength(chainedMemeData.length);
    }
  }, [chainedMemeData, displayMemeIndex]);

  const onClickLeftMeme = () => {
    if (displayMemeIndex - 1 >= 0) {
      setDisplayMemeIndex((prevIndex) => prevIndex - 1);
      updateDisplayMemeIndex(displayMemeIndex - 1);
    } else {
      setDisplayMemeIndex(0);
      updateDisplayMemeIndex(0);
    }
  };

  const onClickRightMeme = () => {
    if (displayMemeIndex + 1 < chainedMemeDataLength) {
      setDisplayMemeIndex((prevIndex) => prevIndex + 1);
      updateDisplayMemeIndex(displayMemeIndex + 1);
    } else {
      setDisplayMemeIndex(chainedMemeDataLength - 1);
      updateDisplayMemeIndex(chainedMemeDataLength - 1);
    }
  };

  return (
    <>
      <div className='flex items-center justify-center space-x-8 md:space-x-4  mb-2'>
        <IconButton
          onClick={onClickLeftMeme}
          sx={{
            "&:hover": {
              backgroundColor: isMobile
                ? "transparent"
                : "rgba(255, 255, 255, 0.3)",
            },
            transition: "background-color 0.2s",
          }}
          style={{ visibility: displayMemeIndex - 1 < 0 && "hidden" }}>
          <SkipPreviousRoundedIcon
            style={{ color: "white", fontSize: isMobile ? 24 : 32 }}
          />
        </IconButton>
        <div className='flex flex-col items-center'>
          <div className='flex text-white text-base md:text-lg items-center justify-center font-bold'>
            <span>{`Clip Chain ${
              displayMemeIndex + 1
            } / ${chainedMemeDataLength}`}</span>
          </div>
        </div>
        <IconButton
          onClick={onClickRightMeme}
          sx={{
            "&:hover": {
              backgroundColor: isMobile
                ? "transparent"
                : "rgba(255, 255, 255, 0.3)",
            },
            transition: "background-color 0.2s",
          }}
          style={{
            visibility:
              displayMemeIndex + 1 >= chainedMemeDataLength && "hidden",
          }}>
          <SkipNextRoundedIcon
            style={{ color: "white", fontSize: isMobile ? 24 : 32 }}
          />
        </IconButton>
      </div>
      <div className='flex items-center justify-center'>
        <TikTokVideoComponent videoPlayUrl={videoPlayUrl} />
      </div>
      <div className='flex items-center justify-center font-bold'>
        <span>{title}</span>
      </div>
      <div className='flex items-left justify-center font-bold'>
        <p className='text-sm text-gray-400 mb-2'>{description}</p>
      </div>
    </>
  );
};

export default BuySellProfileComponent;
