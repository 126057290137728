import React from "react";
import logo from "../assets/clip_logo.png";

const LogoComponent = () => {
  return (
    <div className='flex items-center justify-between'>
      <a href='/'>
        <img src={logo} alt='logo' className='h-12' />
      </a>
    </div>
  );
};

export default LogoComponent;
