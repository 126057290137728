import React, { useEffect, useState, useRef } from "react";
import { getMeme } from "../backendApi/api";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import TradingViewChart from "../components/CandleChartComponent";
import TransactionsComponent from "../components/TransactionsComponent";
import BuySellComponent from "../components/BuySellComponent";
import Tabs from "../components/Tabs";
import TokenInfoComponent from "../components/TokenInfoComponent";
import { SOCKET_SERVER_URL } from "../util/util";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@mui/material";

const BuySellPage = ({ tokenHoldings }) => {
  const { tokenMintAddress } = useParams();

  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [oldTransactions, setOldTransactions] = useState([]);
  const transactionsRef = useRef(transactions);

  const location = useLocation();
  const { state } = location;

  const chainedMemeIndex = state ? state.chainedMemeIndex : 0;

  useEffect(() => {
    transactionsRef.current = transactions;
  }, [transactions]);

  /**
   * useEffect get addresses from tokenMintAddress
   */
  useEffect(() => {
    getMeme(tokenMintAddress)
      .then((res) => {
        if (res.id) {
          setProfile(res);
          setTransactions(res.transactions);
          setOldTransactions(res.transactions);
        } else {
          navigate("/home");
        }
      })
      .catch((err) => {
        console.log("err fetching profile", err);
      });
  }, [tokenMintAddress, navigate]);

  const fetchProfile = (tokenId) => {
    getMeme(tokenId)
      .then((res) => {
        if (res.id) {
          setProfile(res);
        } else {
          alert(
            "Failed to fetch information for this meme token. Please try reloading the page!"
          );
        }
      })
      .catch((err) => {
        console.log("err fetching profile after chaining", err);
      });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const ws = new WebSocket(SOCKET_SERVER_URL);

    ws.addEventListener("open", () => {
      console.log("Connected to WebSocket server");

      // Register the client with the WebSocket server
      ws.send(JSON.stringify({ type: "register", token: tokenMintAddress }));
    });

    ws.addEventListener("message", (message) => {
      try {
        const txs = JSON.parse(message.data);
        setOldTransactions(transactionsRef.current);
        setTransactions(txs);
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    });

    ws.addEventListener("close", () => {
      console.log("Disconnected from WebSocket server");
    });

    ws.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
    });

    return () => {
      ws.close();
    };
  }, []);

  const tabs = [
    { key: "TikTok", label: "TikTok" },
    { key: "Info", label: "Info" },
    { key: "Chart", label: "Chart" },
    { key: "Txs", label: "Txs" },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  return (
    <div>
      {isMobile ? (
        <>
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onClickCallBack={() => {}}
            showBackButton={true}
          />
          <div className='container mx-auto px-4'>
            <BuySellComponent
              profile={profile}
              fetchProfile={fetchProfile}
              isMobile={isMobile}
              tokenHoldings={tokenHoldings}
              transactions={transactions}
              chainedMemeIndex={chainedMemeIndex}
              oldTransactions={oldTransactions}
              setActiveTab={setActiveTab}
              tabs={tabs}
              isActive={activeTab === tabs[0].key}
            />
            <TokenInfoComponent
              profile={profile}
              transactions={transactions}
              isMobile={isMobile}
              isActive={activeTab === tabs[1].key}
            />
            <TradingViewChart
              profile={profile}
              transactions={transactions}
              isActive={activeTab === tabs[2].key}
            />
            <TransactionsComponent
              profile={profile}
              transactions={transactions}
              oldTransactions={oldTransactions}
              isMobile={isMobile}
              isActive={activeTab === tabs[3].key}
            />
          </div>
        </>
      ) : (
        <div className='container mx-auto px-4'>
          <IconButton
            className='hover:font-bold'
            onClick={() =>
              window.history.length > 1 ? navigate(-1) : navigate("/home")
            }>
            <ArrowBackIosIcon
              style={{ color: "white", fontWeight: "inherit" }}
            />
            <p className='text-white text-xl2 underline'>Back</p>
          </IconButton>
          <div className='mx-auto flex flex-col md:flex-row'>
            <div className='w-2/7 md:flex-[2_2_0%]'>
              <BuySellComponent
                profile={profile}
                fetchProfile={fetchProfile}
                tokenHoldings={tokenHoldings}
                transactions={transactions}
                chainedMemeIndex={chainedMemeIndex}
                oldTransactions={oldTransactions}
                isActive={true}
              />
            </div>
            <div className='w-5/7 md:flex-[5_5_0%]'>
              <div className='p-4'>
                <TradingViewChart
                  profile={profile}
                  transactions={transactions}
                  isActive={true}
                />
              </div>
              <div className='p-4'>
                <TransactionsComponent
                  profile={profile}
                  transactions={transactions}
                  oldTransactions={oldTransactions}
                  isActive={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}{" "}
    </div>
  );
};

export default BuySellPage;
