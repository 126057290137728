import React, { useState, useEffect } from "react";
import MemeFeed from "../components/MemeFeed";
import Tabs from "../components/Tabs";
import { useNavigate } from "react-router-dom";

const MemesPage = ({ memes, tabs, activeTab, setActiveTab }) => {
  const tabName = "home";

  useEffect(() => {
    setActiveTab(tabName);
  }, [setActiveTab]);

  const navigate = useNavigate();
  const onClickHomeTabCallBack = (key) => {
    navigate(`/${key}`);
  };

  return (
    <>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onClickCallBack={onClickHomeTabCallBack}
      />
      <div className='container mx-auto px-4'>
        <MemeFeed memes={memes} />
      </div>
    </>
  );
};

export default MemesPage;
