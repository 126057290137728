import React, { useState, useEffect } from "react";
import { marketcapFromPool } from "../util/util";
import "./ChainGradient.css";

const FILTERS = {
  TOP_MCAP: "top_mcap",
  LATEST: "latest",
  MY_TOKENS: "my_tokens",
};

const TokenDetailsComponent = ({ profile, filter, transactions }) => {
  const [lastTransaction, setLastTransaction] = useState(null);
  useEffect(() => {
    if (transactions && transactions.length > 0) {
      setLastTransaction(transactions[0]);
    }
  }, [transactions]);

  const [marketCap, setMarketCap] = useState(0);
  useEffect(() => {
    if (lastTransaction) {
      const { solInPool, tokenInPool } = lastTransaction;
      setMarketCap(marketcapFromPool(solInPool, tokenInPool));
    }
  }, [lastTransaction]);

  const { thumbnailUrl, ticker, description, amount } = profile;

  const displayDescription = (description) => {
    if (description && description.length > 100) {
      return description.slice(0, 100) + "...";
    }
    return description;
  };

  return (
    <div className='flex items-center'>
      {/* Thumbnail image aligned on the left */}
      <img
        style={{ aspectRatio: "3/4" }}
        src={thumbnailUrl}
        alt={ticker}
        className='w-24 h-36 object-cover rounded-lg shadow-lg hover:shadow-xl mr-4'
      />
      {/* Description content aligned on the right */}
      <div className='overflow-hidden'>
        <h2 className='text-base font-semibold mb-2'>Ticker: {ticker}</h2>
        <p className='text-sm text-gray-400 break-words'>
          {displayDescription(description)}
        </p>
        {filter ? (
          <p className='text-sm text-yellow-300'>
            {`Clips Chained: ${profile?.chainedMemeLength}`}
          </p>
        ) : (
          <>
            {/* <p className='text-sm text-blue-300'>
              {`Clips Chained: ${profile?.chainedMemeLength}`}
            </p> */}
            <p className='text-sm text-yellow-300'>Mcap: {marketCap}</p>
          </>
        )}
        {filter === FILTERS.MY_TOKENS && (
          <p className='text-sm text-purple-400'>
            Holdings: {Number(amount).toFixed(0)}
          </p>
        )}
      </div>
    </div>
  );
};

export default TokenDetailsComponent;
