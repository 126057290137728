import {
  program,
  getPoolKey,
  getPoolAuthority,
  ADMIN_KEY,
} from "./util/anchor.ts";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";
import { getAssociatedTokenAddress } from "@solana/spl-token";

export const Chain = async (
  creator: PublicKey,
  ammKey: PublicKey,
  mint: PublicKey,
  doc_id: string
): Promise<TransactionInstruction> => {
  const poolAuthority = await getPoolAuthority(ammKey, mint);
  const poolAccount = await getPoolKey(ammKey, mint);
  const poolAccountA = await getAssociatedTokenAddress(
    mint,
    poolAuthority,
    true
  );
  const creatorAccountA = await getAssociatedTokenAddress(mint, creator, true);
  // lets just make the treasury the admin account for now.
  // later we should have a specific account for this.
  const treasuryAccountA = await getAssociatedTokenAddress(
    mint,
    ADMIN_KEY,
    true
  );

  return program.methods
    .createChain(doc_id)
    .accounts({
      amm: ammKey,
      payer: creator,
      pool: poolAccount,
      poolAuthority,
      mintA: mint,
      poolAccountA,
      creator,
      creatorAccountA,
      treasury: ADMIN_KEY,
      treasuryAccountA,
    })
    .instruction();
};
